import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import NavigationBar from "../components/NavigationBar";
import OptionButton from "../components/OptionButton";
import Modal from "react-modal";
import MessageBox from "../components/MessageBox";
import { post } from "../js/post";
import { getToken, isAdmin, getUsername } from "../js/userData";
import {
  generateSourceComponent,
  generateSourceStructure,
} from "../js/functions";
import { convertToLocalDate } from "../js/utils";
import { LoadingInfo } from "../components/LoadingInfo";
import ImageGallery from "react-image-gallery";
import RichTextEditor from "../components/RichTextEditor";
import SelectMultiple from "../components/SelectMultiple";
import Card from "../components/Card";
import "../css/Resource.css";
import "../css/Modal.css";
import "react-slideshow-image/dist/styles.css";
import "../css/Slide.css";
import "react-image-gallery/styles/css/image-gallery.css";

export default function Resource() {
  const [op, SetOp] = useState(0); // 0: view, 1: create/edit
  const [searchParams] = useSearchParams();
  const [resource, setResource] = useState({});
  const [resourceEdit, setResourceEdit] = useState({});
  const [resourceTags, setResourceTags] = useState([]);
  const [resourceTagsEdit, setResourceTagsEdit] = useState([]);
  const [resourceMedias, setResourceMedias] = useState([]);
  const [resourceMediasEdit, setResourceMediasEdit] = useState([]);
  const [resourceSource, setResourceSource] = useState({});
  const [resourceSourceEdit, setResourceSourceEdit] = useState({});
  const [resourceVersionsList, setResourceVersionsList] = useState([]);
  const [toggleMetadata, setToggleMetadata] = useState(true);
  const [toggleResourceVersions, setToggleResourceVersions] = useState(true);
  const [toggleTags, setToggleTags] = useState(true);
  const [toggleBasic, setToggleBasic] = useState(true);
  const [toggleSourceInfo, setToggleSourceInfo] = useState(true);
  const [toggleAdvanced, setToggleAdvanced] = useState(false);
  const [togglePostliteraryUse, setTogglePostliteraryUse] = useState(false);
  const [toggleAdditionalInfo, setToggleAdditionalInfo] = useState(false);
  const [toggleMetadataEdit, setToggleMetadataEdit] = useState(false);
  const [togglePostliteraryUseStrategies, setTogglePostliteraryUseStrategies] =
    useState(false);
  const [togglePostliteraryUseStyle, setTogglePostliteraryUseStyle] =
    useState(false);
  const [togglePostliteraryUseMode, setTogglePostliteraryUseMode] =
    useState(false);
  const [
    togglePostliteraryUseCanonicalRegime,
    setTogglePostliteraryUseCanonicalRegime,
  ] = useState(false);
  const [newVersion, setNewVersion] = useState(false);
  const [publishVersionOp, setPublishVersionOp] = useState(false);
  const [deleteResourceOp, setDeleteResourceOp] = useState(false);
  const [discardDraftOp, setDiscardDraftOp] = useState(false);
  const [markAsReviewedOp, setMarkAsReviewedOp] = useState(false);
  const [nonExistentResource, setNonExistentResource] = useState(false);
  // Select lists.
  const [languagesList, setLanguagesList] = useState([]);
  const [localLanguagesList, setLocalLanguagesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [sourceCountriesList, setSourceCountriesList] = useState([]);
  const [ownershipsList, setOwnershipsList] = useState([]);
  const [rightsList, setRightsList] = useState([]);
  const [acquisitionWaysList, setAcquisitionWaysList] = useState([]);
  const [strategiesList, setStrategiesList] = useState([]);
  const [stylesList, setStylesList] = useState([]);
  const [modesList, setModesList] = useState([]);
  const [canonicalRegimesList, setCanonicalRegimesList] = useState([]);
  const [formatsList] = useState([
    { id: "Physical", name: "Physical" },
    { id: "Digital", name: "Digital" },
  ]);
  const [PStoreList, setPStoreList] = useState([]);
  const [PClothesList, setPClothesList] = useState([]);
  const [sourcesList, setSourcesList] = useState([]);
  // Tags
  const [newTag, setNewTag] = useState("");
  // Related Resources
  const [relatedResources, setRelatedResources] = useState([]);
  const [newRelatedResource, setNewRelatedResource] = useState("");
  // Media
  const [newMedia] = useState("");
  const [uploadingMediaID, setUpdloadingMediaID] = useState("");
  const [uploadingMedia, setUpdloadingMedia] = useState(false);
  // Errors
  const [errorPublishDraft, setErrorPublishDraft] = useState(false);
  // Suggestions
  const [referredAuthorSuggestionList, setReferredAuthorSuggestionList] =
    useState([]);
  const [
    referredLiteraryWorkSuggestionList,
    setReferredLiteraryWorkSuggestionList,
  ] = useState([]);
  const [tagSuggestionList, setTagSuggestionList] = useState([]);
  const [relatedResourceSuggestionList, setRelatedResourceSuggestionList] =
    useState([]);
  // Image Gallery
  const [toggleFullScreenGallery, setToggleFullScreenGallery] = useState(false);
  // Toggle Save Draft and Mark as Reviewed
  const [toggleSave, setToggleSave] = useState(false);
  // Toggle Change Resource Version
  const [toggleChangeVersion, setToggleChangeVersion] = useState(false);
  // Saved Time for message box
  const [savedDatetime, setSavedDatetime] = useState("");
  // Deadline Datetime
  const [deadlineDatetime, setDeadlineDatetime] = useState("");
  const [remainingHours, setRemainingHours] = useState(0);
  const NUMHOURS = 168;

  const getResource = async (versionCode) => {
    const response = await post("GetResource", {
      token: getToken(),
      id: searchParams.get("resourceId"),
      versionCode,
    });
    if (response.title == null) {
      // No resource exists
      window.location = "/";
    } else {
      setResource({
        ...response,
        resourceId: searchParams.get("resourceId"),
      });
      setSavedDatetime(response.savedDraftDateTime);

      /* CALCULATE DEADLINE DATETIME */
      if (response.loadAsDraft === "1") {
        const date = new Date(response.creationDraftDateTime);
        date.setTime(date.getTime() + NUMHOURS * 60 * 60 * 1000);
        const currentDate = new Date();
        let diff = (date.getTime() - currentDate.getTime()) / 1000 / (60 * 60);
        setRemainingHours(Math.abs(Math.round(diff)));
        setDeadlineDatetime(date);
      }
      /* --------------------------- */

      if (response.loadAsDraft === "1") {
        SetOp(1);
        setToggleAdvanced(false);
        setTogglePostliteraryUse(false);
        setToggleAdditionalInfo(false);
      } else {
        SetOp(0);
        setToggleAdvanced(true);
        setTogglePostliteraryUse(true);
        setToggleAdditionalInfo(true);
      }

      const relatedResourcesAux = response.relatedResources.map(
        ({ referenceId, title }) => `${referenceId} - ${title}`
      );
      setRelatedResources(relatedResourcesAux);

      setResourceEdit({
        token: getToken(),
        resourceId: searchParams.get("resourceId"),
        // Basic
        title: response.title,
        referredAuthor: response.referredAuthor,
        referredLiteraryWork: response.referredLiteraryWork,
        acquisitionWayValue: response.acquisitionWayValue,
        formatValue: response.formatValue,
        sourceValue: response.sourceValue,
        activeResource: response.activeResource,
        link: response.link,
        // Source Info
        sourceInfo: resourceSourceEdit,
        // Advanced
        ownerShipValue: response.ownerShipValue,
        rightsValue: response.rightsValue,
        literarySystem: response.literarySystem,
        originalLanguageValue: response.originalLanguageValue,
        resourcesLanguageValue: response.resourcesLanguageValue,
        // Postliterary Use
        strategies: response.strategies,
        styles: response.styles,
        modes: response.modes,
        canonicalRegimes: response.canonicalRegimes,
        // Metadata
        uploadLocation: response.uploadLocation,
        uploadCountryValue: response.uploadCountryValue,
        // Additional Info
        comments: response.comments,
        relatedResources: relatedResourcesAux,
      });
      getSourcesList(response.formatValue);
      getResourceTags(response);
      getMedias(response.versionCode, response.type);
      getSource(response.versionCode);
      setToggleChangeVersion(false);
    }
  };

  const updateStrategiesResource = (e) => {
    let list = resourceEdit.strategies;
    const found = resourceEdit.strategies.some(
      (item) => item.strategiesValue === e.id
    );
    if (!found) {
      list.push({
        strategiesValue: e.id,
        strategiesLabel: e.name,
      });
    } else {
      list = list.filter((item) => item.strategiesValue !== e.id);
    }
    setResourceEdit({
      ...resourceEdit,
      strategies: list,
    });
  };

  const updateStylesResource = (e) => {
    let list = resourceEdit.styles;
    const found = resourceEdit.styles.some((item) => item.stylesValue === e.id);
    if (!found) {
      list.push({
        stylesValue: e.id,
        stylesLabel: e.name,
      });
    } else {
      list = list.filter((item) => item.stylesValue !== e.id);
    }
    setResourceEdit({
      ...resourceEdit,
      styles: list,
    });
  };

  const updateModesResource = (e) => {
    let list = resourceEdit.modes;
    const found = resourceEdit.modes.some((item) => item.modesValue === e.id);
    if (!found) {
      list.push({
        modesValue: e.id,
        modesLabel: e.name,
      });
    } else {
      list = list.filter((item) => item.modesValue !== e.id);
    }
    setResourceEdit({
      ...resourceEdit,
      modes: list,
    });
  };

  const updateCanonicalRegimesResource = (e) => {
    let list = resourceEdit.canonicalRegimes;
    const found = resourceEdit.canonicalRegimes.some(
      (item) => item.canonicalRegimesValue === e.id
    );
    if (!found) {
      list.push({ canonicalRegimesValue: e.id, canonicalRegimesLabel: e.name });
    } else {
      list = list.filter((item) => item.canonicalRegimesValue !== e.id);
    }
    setResourceEdit({
      ...resourceEdit,
      canonicalRegimes: list,
    });
  };

  const toggleStrategies = () => {
    setTogglePostliteraryUseStrategies(!togglePostliteraryUseStrategies);
    setTogglePostliteraryUseStyle(false);
    setTogglePostliteraryUseMode(false);
    setTogglePostliteraryUseCanonicalRegime(false);
  };

  const toggleStyle = () => {
    setTogglePostliteraryUseStrategies(false);
    setTogglePostliteraryUseStyle(!togglePostliteraryUseStyle);
    setTogglePostliteraryUseMode(false);
    setTogglePostliteraryUseCanonicalRegime(false);
  };

  const toggleMode = () => {
    setTogglePostliteraryUseStrategies(false);
    setTogglePostliteraryUseStyle(false);
    setTogglePostliteraryUseMode(!togglePostliteraryUseMode);
    setTogglePostliteraryUseCanonicalRegime(false);
  };

  const toggleCanonicalRegime = () => {
    setTogglePostliteraryUseStrategies(false);
    setTogglePostliteraryUseStyle(false);
    setTogglePostliteraryUseMode(false);
    setTogglePostliteraryUseCanonicalRegime(
      !togglePostliteraryUseCanonicalRegime
    );
  };

  const deleteResource = async () => {
    await post("DeleteResource", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
    });
  };

  const getNoMediaType = (type) => {
    let img = "";
    switch (type) {
      case "image":
        img = "images/no-image.png";
        break;
      case "audio":
        img = "images/no-audio.png";
        break;
      case "video":
        img = "images/no-video.png";
        break;
      default:
        break;
    }
    return img;
  };

  const getMedias = async (versionCode, type) => {
    let response = await post("GetMedias", {
      resourceId: searchParams.get("resourceId"),
      versionCode: versionCode,
    });
    const mediasEdit = [];
    if (response.length === 0) {
      // No media
      response.push({ id: "", url: getNoMediaType(type) });
      mediasEdit.push({ id: "", url: getNoMediaType(type) });
      mediasEdit.push({ id: "", url: getNoMediaType(type) });
      mediasEdit.push({ id: "", url: getNoMediaType(type) });
      mediasEdit.push({ id: "", url: getNoMediaType(type) });
    } else {
      response.forEach((media) => {
        if (media.id === "") {
          mediasEdit.push({ id: "", url: getNoMediaType(type) });
        } else {
          mediasEdit.push({ id: media.id, url: media.url });
        }
      });
      if (mediasEdit.length === 1) {
        mediasEdit.push({ id: "", url: getNoMediaType(type) });
        mediasEdit.push({ id: "", url: getNoMediaType(type) });
        mediasEdit.push({ id: "", url: getNoMediaType(type) });
      } else if (mediasEdit.length === 2) {
        mediasEdit.push({ id: "", url: getNoMediaType(type) });
        mediasEdit.push({ id: "", url: getNoMediaType(type) });
      } else if (mediasEdit.length === 3) {
        mediasEdit.push({ id: "", url: getNoMediaType(type) });
      }
    }
    response = response.map((e) => {
      e.original = e.url;
      e.thumbnail = e.url;
      return e;
    });
    setResourceMedias(response);
    setResourceMediasEdit(mediasEdit);
  };

  const generateMediasComponent = () => {
    const medias = resourceMediasEdit.map((e, i) => {
      return (
        <div className="lightbox-image-container" key={i}>
          <img
            src={
              uploadingMedia && uploadingMediaID === i
                ? "images/uploading-media.gif"
                : e.url
            }
            width="100"
            height="100"
            sizes="100px"
            alt="ResourceImage"
          ></img>
          <div className="lightbox-image-option-container">
            <input
              id="file-input"
              type="file"
              accept="image/*"
              value={newMedia}
              onChange={async (e) => {
                await uploadNewMedia(e.target.files[0]);
              }}
            ></input>
            <label htmlFor="file-input">
              <img
                src="images/add.svg"
                alt="Icon"
                className="image-7"
                title="Add image"
                onClick={() => {
                  setUpdloadingMediaID(i);
                }}
              ></img>
            </label>
            <img
              src="images/cerrar-3.svg"
              alt="Icon"
              className="image-7"
              title="Delete image"
              onClick={async () => {
                await deleteMedia(e.id);
              }}
            ></img>
          </div>
        </div>
      );
    });
    return medias;
  };

  const getSource = async (versionCode) => {
    const response = await post("GetSource", {
      resourceId: searchParams.get("resourceId"),
      versionCode,
    });
    setResourceSource(response);
    setResourceSourceEdit(response.contents);
  };

  const generateSourceComponentEdit = () => {
    if (resourceSource.source) {
      let component = (
        <div className="section-content-info">
          {[
            "P_Book",
            "P_Article",
            "P_Press",
            "P_Magazine",
            "P_Brochure",
            "D_Press",
            "D_Magazine",
            "P_Performance",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Title</div>
              <input
                id="title1"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.title}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    title: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "D_BlogForumPost" && (
            <div>
              <div className="field-title">Blog Name</div>
              <input
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.blogName}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    blogName: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Book",
            "P_ArtCraft",
            "P_Music",
            "P_Statue",
            "P_Painting",
            "P_Illustration",
            "P_StreetArt",
            "P_AirCraft",
            "D_BlogForumPost",
            "D_Music",
            "D_Painting",
            "D_Illustration",
            "D_SocialMediaPost",
            "P_Decorative",
            "P_Poster",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Author</div>
              <input
                id="author1"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.author}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    author: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Stamp"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Country</div>
              <select
                id="field-country"
                className="select-field select-extra w-select"
                value={resourceSourceEdit.country}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    country: e.target.value,
                  });
                }}
              >
                {sourceCountriesList.map((option, key) => (
                  <option key={key} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {[
            "P_Article",
            "P_Press",
            "P_Magazine",
            "D_Press",
            "D_Magazine",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Authors</div>
              <input
                id="authors"
                type="text"
                className="input-field-value w-input"
                maxLength="300"
                value={resourceSourceEdit.authors}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    authors: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Radio", "P_TV", "D_Radio", "D_TV", "D_Podcast"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Program Name</div>
              <input
                id="programName"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.programName}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    programName: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Radio", "P_TV", "D_Radio", "D_TV", "D_Podcast"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Producer</div>
              <input
                id="producer"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.producer}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    producer: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["D_Press", "P_Press"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Newspaper</div>
              <input
                id="newspaper"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.newspaper}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    newspaper: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Music", "D_Music"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Song</div>
              <input
                id="song"
                type="text"
                className="input-field-value w-input"
                maxLength="300"
                value={resourceSourceEdit.song}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    song: e.target.value,
                  });
                  saveSourceEditOnResourceEdit();
                }}
              ></input>
            </div>
          )}
          {["P_Video", "D_Video"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Name</div>
              <input
                id="name"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.name}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    name: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["D_Video", "P_Video"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Content Creator</div>
              <input
                id="contentCreator"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.contentCreator}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    contentCreator: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["D_Video", "D_Music", "D_Podcast", "D_SocialMediaPost"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Platform</div>
              <input
                id="platform"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.platform}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    platform: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "P_Article" && (
            <div>
              <div className="field-title">Journal</div>
              <input
                id="journal"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.journal}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    journal: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "P_Currency" && (
            <div>
              <div className="field-title">Original Value</div>
              <input
                id="originalValue"
                type="text"
                className="input-field-value w-input"
                maxLength="50"
                value={resourceSourceEdit.originalValue}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    originalValue: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "D_Web" && (
            <div>
              <div className="field-title">Owner</div>
              <input
                id="owner"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.owner}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    owner: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "D_Database" && (
            <div>
              <div className="field-title">Database Name</div>
              <input
                id="databaseName"
                type="text"
                className="input-field-value w-input"
                maxLength="50"
                value={resourceSourceEdit.databaseName}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    databaseName: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "D_Database" && (
            <div>
              <div className="field-title">Language</div>
              <input
                id="language"
                type="text"
                className="input-field-value w-input"
                maxLength="45"
                value={resourceSourceEdit.language}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    language: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Photography", "D_Photography", "D_Database"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Photographer</div>
              <input
                id="photographer"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.photographer}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    photographer: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Painting", "D_Painting"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Location</div>
              <input
                id="location"
                type="text"
                className="input-field-value w-input"
                maxLength="150"
                value={resourceSourceEdit.location}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    location: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Advertisement",
            "D_Advertisement",
            "P_Display",
            "P_Brochure",
            "P_ChirographyDisplay",
            "P_Poster",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Sponsor</div>
              <input
                id="sponsor"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.sponsor}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    sponsor: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Book", "D_Banner"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Publisher</div>
              <input
                id="publisher"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.publisher}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    publisher: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Music", "D_Music"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Album</div>
              <input
                id="album"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.album}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    album: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Postcard"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Copywriter</div>
              <input
                id="copywriter"
                type="text"
                className="input-field-value w-input"
                maxLength="300"
                value={resourceSourceEdit.copywriter}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    copywriter: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "P_Performance" && (
            <div>
              <div className="field-title">Artist</div>
              <input
                id="artist"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.artist}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    artist: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Magazine", "D_Magazine"].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Magazine</div>
              <input
                id="magazine"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.magazine}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    magazine: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Book", "P_Press", "P_Magazine"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Page</div>
              <input
                id="page"
                type="number"
                className="input-field-value w-input"
                maxLength="11"
                value={resourceSourceEdit.page}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    page: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "P_Article" && (
            <div>
              <div className="field-title">Volume</div>
              <input
                id="volume"
                type="text"
                className="input-field-value w-input"
                maxLength="20"
                value={resourceSourceEdit.volume}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    volume: e.target.value,
                  });
                }}
              ></input>
              <div className="field-title">First Page</div>
              <input
                id="firstPage"
                type="text"
                className="input-field-value w-input"
                maxLength="20"
                value={resourceSourceEdit.firstPage}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    firstPage: e.target.value,
                  });
                }}
              ></input>
              <div className="field-title">Last Page</div>
              <input
                id="lastPage"
                type="text"
                className="input-field-value w-input"
                maxLength="20"
                value={resourceSourceEdit.lastPage}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    lastPage: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Press",
            "P_Magazine",
            "P_TV",
            "P_Radio",
            "D_Press",
            "D_Magazine",
            "D_BlogForumPost",
            "D_Radio",
            "D_TV",
            "D_Podcast",
            "D_Doodle",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Date</div>
              <input
                id="date"
                type="date"
                className="input-field-value w-input"
                value={resourceSourceEdit.date}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    date: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["D_SocialMediaPost", "D_Mail"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Datetime</div>
              <input
                id="datetime"
                type="datetime-local"
                className="input-field-value w-input"
                value={resourceSourceEdit.datetime}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    datetime: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Radio",
            "P_TV",
            "P_Video",
            "P_Music",
            "D_Radio",
            "D_TV",
            "D_Video",
            "D_Music",
            "D_Podcast",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Minute</div>
              <input
                id="minute"
                type="text"
                className="input-field-value w-input"
                maxLength="5"
                value={resourceSourceEdit.minute}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    minute: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "D_Mail" && (
            <div>
              <div className="field-title">From</div>
              <input
                id="from"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.from}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    from: e.target.value,
                  });
                }}
              ></input>
              <div className="field-title">To</div>
              <input
                id="to"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.to}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    to: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {resourceEdit.sourceValue === "P_Clothes" && (
            <div>
              <div className="field-title">Type</div>
              <select
                id="field-type-clothes"
                className="select-field select-extra w-select"
                value={resourceSourceEdit.type}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    type: e.target.value,
                  });
                }}
              >
                {PClothesList.map((option, key) => (
                  <option key={key} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {resourceEdit.sourceValue === "P_Store" && (
            <div>
              <div className="field-title">Type</div>
              <select
                id="field-type-store"
                className="select-field select-extra w-select"
                value={resourceSourceEdit.type}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    type: e.target.value,
                  });
                }}
              >
                {PStoreList.map((option, key) => (
                  <option key={key} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {resourceEdit.sourceValue === "D_Database" && (
            <div>
              <div className="field-title">Type</div>
              <input
                id="type"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.type}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    type: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {["P_Merchandising", "P_Clothes"].includes(
            resourceEdit.sourceValue
          ) && (
            <div>
              <div className="field-title">Brand</div>
              <input
                id="brand"
                type="text"
                className="input-field-value w-input"
                maxLength="100"
                value={resourceSourceEdit.brand}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    brand: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Book",
            "P_Article",
            "P_Music",
            "P_Advertisement",
            "P_Photography",
            "P_Illustration",
            "P_Postcard",
            "P_Currency",
            "P_Stamp",
            "D_AnimatedGIF",
            "D_Banner",
            "D_Music",
            "D_Advertisement",
            "D_Painting",
            "D_Photography",
            "D_Illustration",
            "D_BlogForumPost",
            "P_Display",
            "D_Database",
            "D_Doodle",
            "D_Magazine",
            "D_Mail",
            "D_Meme",
            "D_Other",
            "D_Podcast",
            "D_Press",
            "D_Radio",
            "D_TV",
            "D_SocialMediaPost",
            "D_Video",
            "D_Web",
            "P_ChirographyDisplay",
            "P_ChirographySkin",
            "P_Clothes",
            "P_Decorative",
            "P_Magazine",
            "P_Merchandising",
            "P_Other",
            "P_Painting",
            "P_Performance",
            "P_Poster",
            "P_Press",
            "P_Radio",
            "P_Statue",
            "P_Store",
            "P_StreetArt",
            "P_TV",
            "P_Video",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Year</div>
              <input
                id="year"
                type="number"
                className="input-field-value w-input"
                maxLength="11"
                value={resourceSourceEdit.year}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    year: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Statue",
            "P_Store",
            "P_StreetArt",
            "P_Display",
            "P_Advertisement",
            "P_ArtCraft",
            "P_Article",
            "P_Book",
            "P_Brochure",
            "P_ChirographyDisplay",
            "P_ChirographySkin",
            "P_Clothes",
            "P_Currency",
            "P_Decorative",
            "P_Illustration",
            "P_Magazine",
            "P_Merchandising",
            "P_Music",
            "P_Other",
            "P_Painting",
            "P_Performance",
            "P_Photography",
            "P_Postcard",
            "P_Poster",
            "P_Press",
            "P_Radio",
            "P_Stamp",
            "P_TV",
            "P_Video",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Address</div>
              <input
                id="address"
                type="text"
                className="input-field-value w-input"
                maxLength="150"
                value={resourceSourceEdit.address}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    address: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_Display",
            "P_Advertisement",
            "P_ArtCraft",
            "P_Article",
            "P_Book",
            "P_Brochure",
            "P_ChirographyDisplay",
            "P_ChirographySkin",
            "P_Clothes",
            "P_Currency",
            "P_Decorative",
            "P_Illustration",
            "P_Magazine",
            "P_Merchandising",
            "P_Music",
            "P_Other",
            "P_Painting",
            "P_Performance",
            "P_Photography",
            "P_Postcard",
            "P_Poster",
            "P_Press",
            "P_Radio",
            "P_Stamp",
            "P_Statue",
            "P_Store",
            "P_StreetArt",
            "P_TV",
            "P_Video",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">
                Latitude{" "}
                <span className="coordsMsg">
                  (You can get the coordinates{" "}
                  <a
                    href={"https://www.gps-coordinates.net/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  . Example: 39.48021426757052)
                </span>
              </div>
              <input
                id="latitude"
                type="number"
                className="input-field-value w-input"
                maxLength="50"
                value={resourceSourceEdit.coordinatesLat}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    coordinatesLat: e.target.value,
                  });
                }}
              ></input>
              <div className="field-title">
                Longitude{" "}
                <span className="coordsMsg">
                  (You can get the coordinates{" "}
                  <a
                    href={"https://www.gps-coordinates.net/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  . Example: -6.337936774388291)
                </span>
              </div>
              <input
                id="longitude"
                type="number"
                className="input-field-value w-input"
                maxLength="50"
                value={resourceSourceEdit.coordinatesLong}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    coordinatesLong: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {[
            "P_ArtCraft",
            "P_Brochure",
            "P_Video",
            "P_Merchandising",
            "P_Advertisement",
            "P_Illustration",
            "P_Postcard",
            "P_Stamp",
            "P_Currency",
            "P_AirCraft",
            "P_Performance",
            "P_Poster",
            "P_Decorative",
            "P_ChirographySkin",
            "P_ChirographyDisplay",
            "P_Other",
            "D_Video",
            "D_Advertisement",
            "D_Illustration",
            "D_Meme",
            "D_AnimatedGIF",
            "D_Other",
            "D_SocialMediaPost",
            "D_Mail",
            "D_Banner",
            "D_Doodle",
            "P_Display",
            "D_BlogForumPost",
            "D_Database",
            "D_Magazine",
            "D_Music",
            "D_Painting",
            "D_Photography",
            "D_Podcast",
            "D_Press",
            "D_Radio",
            "D_TV",
            "D_Web",
            "P_Article",
            "P_Book",
            "P_Clothes",
            "P_Magazine",
            "P_Music",
            "P_Painting",
            "P_Photography",
            "P_Press",
            "P_Radio",
            "P_Statue",
            "P_Store",
            "P_StreetArt",
            "P_TV",
          ].includes(resourceEdit.sourceValue) && (
            <div>
              <div className="field-title">Description</div>
              <input
                id="description"
                type="text"
                className="input-field-value w-input"
                maxLength="300"
                value={resourceSourceEdit.description}
                onChange={(e) => {
                  setResourceSourceEdit({
                    ...resourceSourceEdit,
                    description: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
        </div>
      );
      return component;
    }
  };

  const getResourceVersionsList = async () => {
    const response = await post("ListVersionsSelect", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
    });
    setResourceVersionsList(response);
  };

  const getLanguagesList = async () => {
    const response = await post("ListLanguagesSelect", {});
    setLanguagesList(response);
  };

  const getLocalLanguagesList = async () => {
    const response = await post("ListLocalLanguagesSelect", {});
    setLocalLanguagesList(response);
  };

  const getCountriesList = async () => {
    const response = await post("ListCountriesSelect", {});
    setCountriesList(response);
    setSourceCountriesList(response);
  };

  const getOwnershipsList = async () => {
    const response = await post("ListOwnershipsSelect", {});
    setOwnershipsList(response);
  };

  const getRightsList = async () => {
    const response = await post("ListRightsSelect", {});
    setRightsList(response);
  };

  const getAcquisitionWaysList = async () => {
    const response = await post("ListAcquisitionWaysSelect", {});
    setAcquisitionWaysList(response);
  };

  const getSourcesList = async (format) => {
    const response = await post("ListSourcesSelect", {
      type: format,
    });
    setSourcesList(response);
  };

  const getStrategiesListSelect = async () => {
    const response = await post("ListStrategiesSelect", {});
    setStrategiesList(response);
  };

  const getStylesListSelect = async () => {
    const response = await post("ListStylesSelect", {});
    setStylesList(response);
  };

  const getModesListSelect = async () => {
    const response = await post("ListModesSelect", {});
    setModesList(response);
  };

  const getCanonicalRegimesListSelect = async () => {
    const response = await post("ListCanonicalRegimesSelect", {});
    setCanonicalRegimesList(response);
  };

  const getPStoreListSelect = async () => {
    const response = await post("ListP_StoreSelect", {});
    setPStoreList(response);
  };

  const getPClothesListSelect = async () => {
    const response = await post("ListP_ClothesSelect", {});
    setPClothesList(response);
  };

  const getResourceTags = (resource) => {
    let tags = "";
    if (resource.tags.length > 0) {
      tags = resource.tags.map((t, i) => (
        <div
          className="tag-container tag-container-extra"
          key={i}
          onClick={() => {
            window.location = "/?tag=" + t.idTag;
          }}
        >
          {t.tag}
        </div>
      ));
    } else {
      tags = <span className="no-tags-info">No tags found</span>;
    }
    setResourceTags(tags);
    setResourceTagsEdit(resource.tags);
  };

  const createResourceVersion = async () => {
    await post("CreateResourceVersion", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
    });
  };

  const uploadNewMedia = async (file) => {
    const prom = new Promise((resolve) => {
      setUpdloadingMedia(true);
      Resizer.imageFileResizer(
        file,
        3000,
        3000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

    prom.then(async (uri) => {
      const mediaData = {
        token: getToken(),
        resourceId: searchParams.get("resourceId"),
        fileName: file.name,
        img: uri,
      };
      await post("SaveImage", mediaData);
      await getMedias(resource.versionCode, resource.type);
      setUpdloadingMedia(false);
      setUpdloadingMediaID("");
    });
  };

  const deleteMedia = async (id) => {
    await post("DeleteImage", { token: getToken(), mediaId: id });
    await getMedias(resource.versionCode, resource.type);
  };

  const addTag = async () => {
    if (newTag !== "") {
      const tagAux = resourceTagsEdit.find((e) => e.tag === newTag);
      if (!tagAux) {
        await post("AddTag", {
          token: getToken(),
          resourceId: searchParams.get("resourceId"),
          tag: newTag,
        });
        const resourceTagsAux = resourceTagsEdit;
        resourceTagsAux.push({ idTag: newTag, tag: newTag });
        setResourceTagsEdit(resourceTagsAux);
        setNewTag("");
      }
    }
  };

  const addRelatedResource = () => {
    if (
      newRelatedResource !== "" &&
      relatedResources.length < 6 &&
      !relatedResources.includes(newRelatedResource)
    ) {
      setRelatedResources([...relatedResources, newRelatedResource]);
      setNewRelatedResource("");
    }
  };

  const deleteTag = async (tagId) => {
    await post("DeleteTag", {
      token: getToken(),
      tagId,
    });
    let tagsAux = resourceTagsEdit;
    tagsAux = tagsAux.filter((t) => t.idTag !== tagId);
    setResourceTagsEdit(tagsAux);
  };

  const deleteRelatedResource = (relatedResource) => {
    const updatedResources = relatedResources.filter(
      (e) => e !== relatedResource
    );
    setRelatedResources(updatedResources);
  };

  const markResourceVersionAsReviewed = async () => {
    await post("MarkVersionAsReviewed", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
    });
    window.location = "/resource?resourceId=" + searchParams.get("resourceId");
  };

  const publishVersion = async () => {
    await post("PublishVersion", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
    });
    window.location = "/resource?resourceId=" + searchParams.get("resourceId");
  };

  const discardDraft = async () => {
    await post("DiscardDraft", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
    });
  };

  const saveDraft = async () => {
    setErrorPublishDraft(false);
    const currentDate = new Date();
    const savedDatetimeAux = convertToLocalDate(currentDate);
    setSavedDatetime(savedDatetimeAux);

    // Refine related resources
    let relatedResourcesAux = relatedResources;
    relatedResourcesAux = relatedResourcesAux.map((e) => {
      return e.split(" - ")[0];
    });

    let response = await post("SaveDraft", {
      token: getToken(),
      resourceId: searchParams.get("resourceId"),
      // Basic
      title: resourceEdit.title.toUpperCase(),
      referredAuthor: resourceEdit.referredAuthor.toUpperCase(),
      referredLiteraryWork: resourceEdit.referredLiteraryWork.toUpperCase(),
      acquisitionWayValue: resourceEdit.acquisitionWayValue,
      formatValue: resourceEdit.formatValue,
      sourceValue: resourceEdit.sourceValue,
      activeResource: resourceEdit.activeResource,
      link:
        resourceEdit.link !== "" && !resourceEdit.link.startsWith("http")
          ? "https://" + resourceEdit.link
          : resourceEdit.link,
      // Source Info
      sourceInfo: resourceSourceEdit,
      // Advanced
      ownerShipValue: resourceEdit.ownerShipValue,
      rightsValue: resourceEdit.rightsValue,
      literarySystem: resourceEdit.literarySystem,
      originalLanguageValue: resourceEdit.originalLanguageValue,
      resourcesLanguageValue: resourceEdit.resourcesLanguageValue,
      // Postliterary Use
      strategies: resourceEdit.strategies,
      styles: resourceEdit.styles,
      modes: resourceEdit.modes,
      canonicalRegimes: resourceEdit.canonicalRegimes,
      // Metadata
      uploadLocation: resourceEdit.uploadLocation,
      uploadCountryValue: resourceEdit.uploadCountryValue,
      // Additional
      comments: resourceEdit.comments,
      relatedResources: relatedResourcesAux,
    });
    if (response.error === "0") {
      setToggleSave(true);
    } else if (response.error === "1") {
      setNonExistentResource(true);
    }
    setResourceSourceEdit({
      ...resourceSourceEdit,
      id: response.sourceId,
    });

    setTimeout(() => {
      setToggleSave(false);
    }, 2000);
  };

  const saveSourceEditOnResourceEdit = () => {
    setResourceEdit({
      ...resourceEdit,
      sourceInfo: resourceSourceEdit,
    });
  };

  const getTagSuggestions = async (textInput) => {
    const response = await post("TagSuggestions", { textInput });
    setTagSuggestionList(response);
  };

  const getRelatedResourceSuggestions = async (textInput) => {
    const response = await post("ResourceSuggestions", { textInput });
    setRelatedResourceSuggestionList(response);
  };

  const getReferredAuthorSuggestions = async (textInput) => {
    const response = await post("ReferredAuthorSuggestions", { textInput });
    setReferredAuthorSuggestionList(response.sort());
  };

  const getReferredLiteraryWorkSuggestions = async (textInput) => {
    const response = await post("ReferredLiteraryWorkSuggestions", {
      textInput,
    });
    setReferredLiteraryWorkSuggestionList(response);
  };

  useEffect(() => {
    getResource("");
    getLanguagesList();
    getLocalLanguagesList();
    getCountriesList();
    getOwnershipsList();
    getRightsList();
    getAcquisitionWaysList();
    getStrategiesListSelect();
    getStylesListSelect();
    getModesListSelect();
    getCanonicalRegimesListSelect();
    getPStoreListSelect();
    getPClothesListSelect();
    getResourceVersionsList();
    getTagSuggestions("");
    getRelatedResourceSuggestions("");
    getReferredAuthorSuggestions("");
    getReferredLiteraryWorkSuggestions("");
  }, []);

  Modal.setAppElement("#root");

  let showNewVersion =
    getToken() !== "" &&
    resource.hasDraft &&
    resource.hasDraft === "0" &&
    resource.loadAsDraft === "0" &&
    (isAdmin() || resource.uploaderUsername === getUsername());
  let showDeleteVersion = getToken() && isAdmin();
  let showMarkAsReviewed =
    getToken() &&
    isAdmin() &&
    resource.loadAsDraft &&
    resource.loadAsDraft === "0" &&
    resource.hasDraft === "0" &&
    resource.reviewedBy === "";
  let showResourceOptionBar =
    (resource.loadAsDraft && resource.loadAsDraft === "1") ||
    showNewVersion ||
    showDeleteVersion ||
    showMarkAsReviewed;

  return (
    <div className="resource-card">
      {toggleChangeVersion && (
        <LoadingInfo title="Loading resource version..." />
      )}
      {!resource.resourceId && <LoadingInfo title="Loading resource..." />}
      {!toggleChangeVersion &&
        resource.resourceId &&
        resourceMedias.length > 0 && (
          <div>
            <div className="resource-card-header">
              <NavigationBar></NavigationBar>
              <div
                className="resource-card-header-title"
                onClick={() => {
                  window.location = "/";
                }}
              >
                <div className="text-block-4">
                  <strong className="bold-text-3">Branding</strong> the{" "}
                  <strong className="bold-text-4">Canon</strong>
                </div>
              </div>
            </div>
            <div className="resource-card-content">
              <div className="resource-card-title">
                <div className="title-container">
                  <div className="text-block-8">
                    Resource <strong>Info</strong>
                  </div>
                </div>
              </div>
              {resource.loadAsDraft === "1" && (
                <div className="resource-message-container">
                  <span>
                    The current version of this resource is a{" "}
                    <span className="bold-text">draft</span>, which presents a
                    last saved timestamp{" "}
                    <span className="bold-text">
                      {savedDatetime.replaceAll("-", "/")}
                    </span>
                    . If this draft is not published in{" "}
                    <span className="bold-text">{remainingHours} hours</span> (
                    <span className="bold-text">
                      {
                        /*deadlineDatetime
                        .toJSON()
                        .split("T")[0]
                        .replaceAll("-", "/") +
                        " " +
                    deadlineDatetime.toJSON().split("T")[1].split(".")[0]*/
                        convertToLocalDate(deadlineDatetime)
                      }
                    </span>
                    ), it will be permanently and automatically{" "}
                    <span className="bold-text">deleted</span> by the system.
                  </span>
                </div>
              )}
              {resource.loadAsDraft === "2" /* ||
                (resource.loadAsDraft === "0" &&
                  resource.hasDraft === "1") */ && (
                <div className="resource-message-container">
                  <img src="images/warning.svg" alt="Icon"></img>
                  <span>
                    This version is being{" "}
                    <span className="bold-text">modified/reviewed</span> by the
                    user{" "}
                    <span className="bold-text">{resource.ownerDraftName}</span>
                    , presenting a last saved timestamp{" "}
                    <span className="bold-text">
                      {resource.savedDraftDateTime.replaceAll("-", "/")}
                    </span>
                    . Publication deadline:{" "}
                    <span className="bold-text">
                      {resource.uploadDateTime.replaceAll("-", "/")}
                    </span>
                    .
                  </span>
                </div>
              )}
              {showResourceOptionBar && (
                <div className="resource-option-box">
                  <div className="span-button span-options-title">
                    RESOURCE OPTIONS
                  </div>
                  {op === 0 && (
                    <div className="option-button-container">
                      {showNewVersion && (
                        <OptionButton
                          text={"New version"}
                          icon={"../images/new-version.svg"}
                          onPress={() => {
                            setNewVersion(true);
                            setDeleteResourceOp(false);
                          }}
                        />
                      )}
                      {showMarkAsReviewed && (
                        <OptionButton
                          text={"Mark as reviewed"}
                          icon={"../images/revised.svg"}
                          onPress={() => {
                            setMarkAsReviewedOp(true);
                            setPublishVersionOp(false);
                            setDiscardDraftOp(false);
                          }}
                        />
                      )}
                      {showDeleteVersion && (
                        <OptionButton
                          text={"Delete resource"}
                          icon={"../images/remove-resource.svg"}
                          onPress={() => {
                            setDeleteResourceOp(true);
                            setNewVersion(false);
                          }}
                        />
                      )}
                    </div>
                  )}
                  {op === 1 && (
                    <div className="option-button-container">
                      <OptionButton
                        text={"Save draft"}
                        icon={"../images/save.svg"}
                        onPress={() => {
                          saveDraft();
                        }}
                      />
                      <OptionButton
                        text={"Publish version"}
                        icon={"../images/publish.svg"}
                        onPress={() => {
                          setPublishVersionOp(true);
                          setDiscardDraftOp(false);
                          setMarkAsReviewedOp(false);
                        }}
                      />
                      <OptionButton
                        text={"Discard draft"}
                        icon={"../images/discard.svg"}
                        onPress={() => {
                          setDiscardDraftOp(true);
                          setPublishVersionOp(false);
                          setMarkAsReviewedOp(false);
                        }}
                      />
                      <OptionButton
                        text={"Cancel changes"}
                        icon={"../images/close.svg"}
                        onPress={() => {
                          window.location =
                            "/resource?resourceId=" +
                            searchParams.get("resourceId");
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {toggleSave && (
                <div id="saving-container" className={"saving-information"}>
                  <span>
                    The resource has been{" "}
                    <span className="bold-text">successfully saved</span>.
                  </span>
                </div>
              )}
              <Modal isOpen={newVersion} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure you want to{" "}
                      <span className="bold-text">create a new version</span> of
                      the resource?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        setNewVersion(false);
                        await createResourceVersion();
                        window.location =
                          "/resource?resourceId=" +
                          searchParams.get("resourceId");
                      }}
                    >
                      {"CREATE"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setNewVersion(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={publishVersionOp} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure you want to{" "}
                      <span className="bold-text">publish</span> this version?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        await saveDraft();
                        if (
                          !errorPublishDraft &&
                          resourceEdit.title !== "" &&
                          resourceEdit.referredAuthor !== "" &&
                          resourceEdit.acquisitionWayValue !== "" &&
                          resourceEdit.formatValue !== "" &&
                          resourceEdit.sourceValue !== "" &&
                          resourceEdit.ownerShipValue !== "" &&
                          resourceEdit.rightsValue !== "" &&
                          resourceEdit.originalLanguageValue !== "" &&
                          resourceEdit.resourcesLanguageValue !== ""
                        ) {
                          await publishVersion();
                        } else {
                          setErrorPublishDraft(true);
                        }
                        setPublishVersionOp(false);
                      }}
                    >
                      {"PUBLISH"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setPublishVersionOp(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={deleteResourceOp} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure to <span className="bold-text">delete</span>{" "}
                      this resource?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        await deleteResource();
                        window.location = "/";
                      }}
                    >
                      {"DELETE"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setDeleteResourceOp(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={discardDraftOp} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure to{" "}
                      <span className="bold-text">discard changes</span> without
                      saving?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        await discardDraft();
                        setDiscardDraftOp(false);
                        // VersionCode = 1 -> Home, VersionCode > 1 -> Load last version
                        if (resource.versionCode === "1") {
                          window.location = "/";
                        } else {
                          window.location =
                            "/resource?resourceId=" +
                            searchParams.get("resourceId");
                        }
                      }}
                    >
                      {"DISCARD"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setDiscardDraftOp(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={markAsReviewedOp} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure you want to{" "}
                      <span className="bold-text">mark</span> this resource as{" "}
                      <span className="bold-text">reviewed</span>?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        await markResourceVersionAsReviewed();
                        setMarkAsReviewedOp(false);
                        SetOp(0);
                      }}
                    >
                      {"MARK"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setMarkAsReviewedOp(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={nonExistentResource} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      The draft does{" "}
                      <span className="bold-text">not exist</span>
                      {".  "}
                      {resource.versionCode === "1" && (
                        <span>
                          You will be{" "}
                          <span className="bold-text">redirected</span> to the{" "}
                          <span className="bold-text">main page</span>.
                        </span>
                      )}
                      {parseInt(resource.versionCode) > 1 && (
                        <span>
                          The <span className="bold-text">last version</span> of
                          the resource will be{" "}
                          <span className="bold-text">loaded</span>.
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        setNonExistentResource(false);
                        if (resource.versionCode === "1") {
                          window.location = "/";
                        } else if (parseInt(resource.versionCode) > 1) {
                          window.location =
                            "/resource?resourceId=" +
                            searchParams.get("resourceId");
                        }
                      }}
                    >
                      {"OK"}
                    </div>
                  </div>
                </div>
              </Modal>
              {errorPublishDraft && (
                <MessageBox
                  question={
                    "There are errors in the resource fields, please review the information."
                  }
                ></MessageBox>
              )}
              <div className="resource-card-box">
                <div className="rcb-left-panel">
                  <div className="reference-container">
                    <div className="field-title dark-text">Reference ID</div>
                    <div className="text-block-5">{resource.referenceId}</div>
                  </div>
                  <div className="resource-metadata-container">
                    <div
                      className="resource-metadata-container-header"
                      onClick={() => {
                        setToggleMetadata(!toggleMetadata);
                      }}
                    >
                      <img
                        src={
                          toggleMetadata
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="text-block-6">Metadata</div>
                    </div>
                    {toggleMetadata && resource.versionDateTime && (
                      <div className="resource-metadata-container-info">
                        <div className="field-title span-small">
                          Uploader Name
                        </div>
                        <div className="field-value span-small-2 span-img-value">
                          {resource.uploaderName}
                          <img
                            className="image-8"
                            src="/images/user-dark.svg"
                            title="Visit user profile"
                            alt="User icon"
                            onClick={() => {
                              window.open(
                                "/user?userId=" + resource.uploaderUserId
                              );
                            }}
                          ></img>
                        </div>
                        <div className="field-title span-small">
                          Uploader Username
                        </div>
                        <div className="field-value span-small-2">
                          {resource.uploaderUsername}
                        </div>
                        <div className="field-title span-small">
                          Upload Country
                        </div>
                        <div className="field-value span-small-2">
                          {resource.uploadCountryLabel}
                        </div>
                        <div className="field-title span-small">
                          Upload Location
                        </div>
                        <div className="field-value span-small-2">
                          {resource.uploadLocation}
                        </div>
                        <div className="field-title span-small">
                          Upload Datetime
                        </div>
                        <div className="field-value span-small-2">
                          {resource.uploadDateTime.replaceAll("-", "/")}
                        </div>
                        <div className="field-title span-small">
                          Version Code
                        </div>
                        <div className="field-value span-small-2">
                          {resource.versionCode}
                        </div>
                        <div className="field-title span-small">
                          Version Datetime
                        </div>
                        <div className="field-value span-small-2">
                          {resource.versionDateTime.replaceAll("-", "/")}
                        </div>
                        <div className="field-title span-small">
                          Version User
                        </div>
                        <div className="field-value span-small-2">
                          {resource.versionUser}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="resource-metadata-container">
                    <div
                      className="resource-metadata-container-header"
                      onClick={() => {
                        setToggleResourceVersions(!toggleResourceVersions);
                      }}
                    >
                      <img
                        src={
                          toggleResourceVersions
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="text-block-6">Resource Versions</div>
                    </div>
                    {toggleResourceVersions && (
                      <div className="resource-metadata-container-info">
                        <div className="form-block-3 w-form">
                          <select
                            id="field-resource-version"
                            className="select-field select-extra w-select"
                            value={resource.versionCode}
                            onChange={(e) => {
                              setToggleChangeVersion(true);
                              getResource(e.target.value);
                            }}
                          >
                            {resourceVersionsList.map((option, key) => (
                              <option key={key} value={option.versionCode}>
                                {option.referenceId}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  {op === 0 && (
                    <div className="resource-metadata-container">
                      <div
                        className="resource-metadata-container-header"
                        onClick={() => {
                          setToggleTags(!toggleTags);
                        }}
                      >
                        <img
                          src={
                            toggleTags
                              ? "images/close-section.svg"
                              : "images/open-section.svg"
                          }
                          alt="Icon"
                          className="resource-section-icon"
                        ></img>
                        <div className="text-block-6">
                          Tags{" "}
                          {resourceTags.length > 0
                            ? "(" + resourceTags.length + ")"
                            : ""}
                        </div>
                      </div>
                      {toggleTags && (
                        <div className="resource-metadata-container-info resource-tags-container">
                          {resourceTags}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="rcb-right-panel">
                  <div className="resource-section">
                    <div
                      className="resource-section-title"
                      onClick={() => {
                        setToggleBasic(!toggleBasic);
                      }}
                    >
                      <img
                        src={
                          toggleBasic
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="15"
                        width="15"
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="span-section-title">Basic</div>
                    </div>
                    {toggleBasic && op === 0 && (
                      <div className="resource-section-content">
                        <div
                          className={`resource-medias-container ${
                            newVersion || deleteResourceOp
                              ? "gallery-buttons-custom"
                              : ""
                          }`}
                        >
                          <ImageGallery
                            items={resourceMedias}
                            showPlayButton={false}
                            showThumbnails={false}
                            showBullets={resourceMedias[0].id !== ""}
                            showFullscreenButton={resourceMedias[0].id !== ""}
                            onScreenChange={() => {
                              setToggleFullScreenGallery(
                                !toggleFullScreenGallery
                              );
                            }}
                            additionalClass={
                              toggleFullScreenGallery
                                ? ""
                                : "image-gallery-extra"
                            }
                          ></ImageGallery>
                        </div>
                        <div className="section-content-info">
                          <div className="field-title">Title</div>
                          <div className="field-value">{resource.title}</div>
                          <div className="label-icon-container">
                            <div className="field-title">Referred Author</div>
                            <img
                              src="images/access-search.svg"
                              alt="Icon"
                              title="Search by referred author"
                              className="image-8"
                              onClick={() => {
                                window.location =
                                  "/?referredAuthor=" + resource.referredAuthor;
                              }}
                            ></img>
                          </div>
                          <div className="field-value">
                            {resource.referredAuthor}
                          </div>
                          <div className="label-icon-container">
                            <div className="field-title">
                              Referred Literary Work
                            </div>
                            {resource.referredLiteraryWork !== "" && (
                              <img
                                src="images/access-search.svg"
                                alt="Icon"
                                title="Search by referred literary work"
                                className="image-8"
                                onClick={() => {
                                  window.location =
                                    "/?literaryWork=" +
                                    resource.referredLiteraryWork;
                                }}
                              ></img>
                            )}
                          </div>
                          <div className="field-value">
                            {resource.referredLiteraryWork !== ""
                              ? resource.referredLiteraryWork
                              : "-"}
                          </div>
                          <div className="field-title">Acquisition Way</div>
                          <div className="field-value">
                            {resource.acquisitionWayLabel}
                          </div>
                          <div className="field-title">Format</div>
                          <div className="field-value">
                            {resource.formatLabel}
                          </div>
                          {resource.formatLabel === "Digital" && (
                            <div className="resource-link">
                              <div className="field-title">Link</div>
                              <div
                                className={`field-value field-link ${
                                  resource.activeResource === "0"
                                    ? "inactive-link"
                                    : ""
                                }`}
                              >
                                <a
                                  className={`${
                                    resource.activeResource === "0"
                                      ? "not-active"
                                      : ""
                                  }`}
                                  href={
                                    resource.link !== "" ? resource.link : ""
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {resource.link !== "" ? resource.link : "-"}
                                </a>
                              </div>
                            </div>
                          )}
                          <div className="field-title">Source</div>
                          <div className="field-value">
                            {resource.sourceLabel}
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleBasic && op === 1 && (
                      <div>
                        <div className="resource-section-content">
                          <div className="lightbox-link w-inline-block w-lightbox">
                            {generateMediasComponent()}
                          </div>
                          <div className="section-content-info">
                            <div className="form-block-9 w-form">
                              <div className="field-title">Title (*)</div>
                              <input
                                id="title"
                                type="text"
                                className="input-field-value w-input input-uc"
                                maxLength="256"
                                name="name"
                                data-name="Name"
                                placeholder=""
                                value={resourceEdit.title}
                                onChange={(e) => {
                                  setResourceEdit({
                                    ...resourceEdit,
                                    title: e.target.value,
                                  });
                                }}
                              ></input>
                              <div className="field-title">
                                Referred Author (*)
                              </div>
                              <input
                                type="text"
                                className="input-field-value w-input input-uc"
                                maxLength="256"
                                name="name-2"
                                data-name="Name 2"
                                placeholder=""
                                id="referredAuthor"
                                value={resourceEdit.referredAuthor}
                                onChange={(e) => {
                                  setResourceEdit({
                                    ...resourceEdit,
                                    referredAuthor: e.target.value,
                                  });
                                  getReferredAuthorSuggestions(e.target.value);
                                }}
                              ></input>
                              <div className="suggestion-container">
                                <img src="images/info.svg" alt="icon"></img>
                                <div className="field-title suggestion-container-span">
                                  Suggestions (
                                  {referredAuthorSuggestionList.length}
                                  ):
                                </div>
                                <div className="suggestion-container-list">
                                  {referredAuthorSuggestionList.length > 0 &&
                                    referredAuthorSuggestionList.map((e, i) => {
                                      return (
                                        <span
                                          className="suggestion-span"
                                          key={i}
                                          onClick={() => {
                                            setResourceEdit({
                                              ...resourceEdit,
                                              referredAuthor: e,
                                            });
                                          }}
                                        >
                                          {e}
                                          {i ===
                                          referredAuthorSuggestionList.length -
                                            1
                                            ? ""
                                            : ", "}
                                        </span>
                                      );
                                    })}
                                  {referredAuthorSuggestionList.length ===
                                    0 && (
                                    <span className="no-suggestion-span">
                                      No suggestions found.
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="field-title">
                                Referred Literary Work
                              </div>
                              <input
                                type="text"
                                className="input-field-value w-input input-uc"
                                maxLength="256"
                                name="name-3"
                                data-name="Name 3"
                                placeholder=""
                                id="referredLiteraryWork"
                                value={resourceEdit.referredLiteraryWork}
                                onChange={(e) => {
                                  setResourceEdit({
                                    ...resourceEdit,
                                    referredLiteraryWork: e.target.value,
                                  });
                                  getReferredLiteraryWorkSuggestions(
                                    e.target.value
                                  );
                                }}
                              ></input>
                              <div className="suggestion-container">
                                <img src="images/info.svg" alt="icon"></img>
                                <div className="field-title suggestion-container-span">
                                  Suggestions (
                                  {referredLiteraryWorkSuggestionList.length}
                                  ):
                                </div>
                                <div className="suggestion-container-list">
                                  {referredLiteraryWorkSuggestionList.length >
                                    0 &&
                                    referredLiteraryWorkSuggestionList.map(
                                      (e, i) => {
                                        return (
                                          <span
                                            className="suggestion-span"
                                            key={i}
                                            onClick={() => {
                                              setResourceEdit({
                                                ...resourceEdit,
                                                referredLiteraryWork: e,
                                              });
                                            }}
                                          >
                                            {e}
                                            {i ===
                                            referredLiteraryWorkSuggestionList.length -
                                              1
                                              ? ""
                                              : ", "}
                                          </span>
                                        );
                                      }
                                    )}
                                  {referredLiteraryWorkSuggestionList.length ===
                                    0 && (
                                    <span className="no-suggestion-span">
                                      No suggestions found.
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="field-title">
                                Acquisition Way (*)
                              </div>
                              <select
                                id="field-acquisition-wway"
                                className="select-field select-extra w-select"
                                value={resourceEdit.acquisitionWayValue}
                                onChange={(e) => {
                                  setResourceEdit({
                                    ...resourceEdit,
                                    acquisitionWayValue: e.target.value,
                                  });
                                }}
                              >
                                {acquisitionWaysList.map((option, key) => (
                                  <option key={key} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              <div className="field-title">Format (*)</div>
                              <select
                                id="field-format"
                                className="select-field select-extra w-select"
                                value={resourceEdit.formatValue}
                                onChange={(e) => {
                                  getSourcesList(e.target.value);
                                  setResourceEdit({
                                    ...resourceEdit,
                                    formatValue: e.target.value,
                                    sourceValue: sourcesList[0].categoryId,
                                  });
                                  const struct = generateSourceStructure(
                                    sourcesList[0].categoryId
                                  );
                                  setResourceSourceEdit(struct);
                                }}
                              >
                                {formatsList.map((option, key) => (
                                  <option key={key} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              {resourceEdit.formatValue === "Digital" && (
                                <div>
                                  <div className="field-title">Link</div>
                                  <input
                                    type="text"
                                    className="input-field-value w-input"
                                    maxLength="512"
                                    name="name"
                                    data-name="Name"
                                    placeholder=""
                                    value={resourceEdit.link}
                                    onChange={(e) => {
                                      setResourceEdit({
                                        ...resourceEdit,
                                        link: e.target.value,
                                      });
                                    }}
                                    id="link"
                                  ></input>
                                  <div className="field-title">
                                    Link Active?
                                  </div>
                                  <div className="resource-check-container">
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="radio"
                                        id="linkActiveYes"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input"
                                        checked={
                                          resourceEdit.activeResource === "1"
                                        }
                                        onChange={() => {
                                          setResourceEdit({
                                            ...resourceEdit,
                                            activeResource: "1",
                                          });
                                        }}
                                      ></input>
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        Yes
                                      </span>
                                    </label>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="radio"
                                        id="linkActiveNo"
                                        name="checkbox-2"
                                        data-name="Checkbox 2"
                                        className="w-checkbox-input"
                                        checked={
                                          resourceEdit.activeResource === "0"
                                        }
                                        onChange={() => {
                                          setResourceEdit({
                                            ...resourceEdit,
                                            activeResource: "0",
                                          });
                                        }}
                                      ></input>
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox-2"
                                      >
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                              <div className="field-title">Source (*)</div>
                              <select
                                id="field-source"
                                className="select-field select-extra w-select"
                                value={resourceEdit.sourceValue}
                                onChange={(e) => {
                                  setResourceEdit({
                                    ...resourceEdit,
                                    sourceValue: e.target.value,
                                  });
                                  const struct = generateSourceStructure(
                                    e.target.value
                                  );
                                  setResourceSourceEdit(struct);
                                }}
                              >
                                {sourcesList.map((option, key) => (
                                  <option key={key} value={option.categoryId}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="resource-section">
                    <div
                      className="resource-section-title"
                      onClick={() => {
                        setToggleSourceInfo(!toggleSourceInfo);
                      }}
                    >
                      <img
                        src={
                          toggleSourceInfo
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="20"
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="span-section-title">Source Info</div>
                    </div>
                    {toggleSourceInfo && op === 0 && resourceSource && (
                      <div className="resource-section-content">
                        {generateSourceComponent(resourceSource, countriesList)}
                      </div>
                    )}
                    {toggleSourceInfo && op === 1 && (
                      <div className="resource-section-content">
                        {generateSourceComponentEdit()}
                      </div>
                    )}
                  </div>
                  <div className="resource-section">
                    <div
                      className="resource-section-title"
                      onClick={() => {
                        setToggleAdvanced(!toggleAdvanced);
                      }}
                    >
                      <img
                        src={
                          toggleAdvanced
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="20"
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="span-section-title">Advanced</div>
                    </div>
                    {toggleAdvanced && op === 0 && (
                      <div className="resource-section-content">
                        <div className="section-content-info">
                          <div className="field-title">Ownership</div>
                          <div className="field-value">
                            {resource.ownerShipLabel}
                          </div>
                          <div className="field-title">Rights</div>
                          <div className="field-value">
                            {resource.rightsLabel}
                          </div>
                          <div className="field-title">Literary System</div>
                          <div className="field-value">
                            {resource.literarySystem}
                          </div>
                          <div className="field-title">Original Language</div>
                          <div className="field-value">
                            {resource.originalLanguageLabel}
                          </div>
                          <div className="field-title">
                            Resource&#x27;s Language
                          </div>
                          <div className="field-value">
                            {resource.resourcesLanguageLabel}
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleAdvanced && op === 1 && (
                      <div className="resource-section-content">
                        <div className="section-content-info">
                          <div className="form-block-7 w-form">
                            <div className="field-title">Ownership (*)</div>
                            <select
                              id="field-ownership"
                              className="select-field select-extra w-select"
                              value={resourceEdit.ownerShipValue}
                              onChange={(e) => {
                                setResourceEdit({
                                  ...resourceEdit,
                                  ownerShipValue: e.target.value,
                                });
                              }}
                            >
                              {ownershipsList.map((option, key) => (
                                <option key={key} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            <div className="field-title">Rights (*)</div>
                            <select
                              id="field-rights"
                              className="select-field select-extra w-select"
                              value={resourceEdit.rightsValue}
                              onChange={(e) => {
                                setResourceEdit({
                                  ...resourceEdit,
                                  rightsValue: e.target.value,
                                });
                              }}
                            >
                              {rightsList.map((option, key) => (
                                <option key={key} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            <div className="field-title">Literary System</div>
                            <input
                              type="text"
                              className="input-field-value w-input"
                              maxLength="256"
                              name="name-5"
                              data-name="Name 5"
                              placeholder=""
                              id="literarySystem"
                              value={resourceEdit.literarySystem}
                              onChange={(e) => {
                                setResourceEdit({
                                  ...resourceEdit,
                                  literarySystem: e.target.value,
                                });
                              }}
                            ></input>
                            <div className="field-title">
                              Resource&#x27;s Language (*)
                            </div>
                            <select
                              id="field-language"
                              className="select-field select-extra w-select"
                              value={resourceEdit.originalLanguageValue}
                              onChange={(e) => {
                                setResourceEdit({
                                  ...resourceEdit,
                                  originalLanguageValue: e.target.value,
                                });
                              }}
                            >
                              {languagesList.map((option, key) => (
                                <option key={key} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            <div className="field-title">
                              Original Language (*)
                            </div>
                            <select
                              id="field-original-language"
                              className="select-field select-extra w-select"
                              value={resourceEdit.resourcesLanguageValue}
                              onChange={(e) => {
                                setResourceEdit({
                                  ...resourceEdit,
                                  resourcesLanguageValue: e.target.value,
                                });
                              }}
                            >
                              {localLanguagesList.map((option, key) => (
                                <option key={key} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="resource-section">
                    <div
                      className="resource-section-title"
                      onClick={() => {
                        setTogglePostliteraryUse(!togglePostliteraryUse);
                      }}
                    >
                      <img
                        src={
                          togglePostliteraryUse
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="20"
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="span-section-title">Postliterary Use</div>
                    </div>
                    {togglePostliteraryUse && op === 0 && (
                      <div className="resource-section-content">
                        <div className="section-content-info">
                          <div className="field-title">Strategies</div>
                          <div className="field-value element-container">
                            {resource.strategies.length === 0 ? (
                              <span>-</span>
                            ) : (
                              resource.strategies.map((item, i) => {
                                return (
                                  <span key={i}>
                                    {item.strategiesLabel}
                                    {i < resource.strategies.length - 1
                                      ? ","
                                      : ""}
                                  </span>
                                );
                              })
                            )}
                          </div>
                          <div className="field-title">Style</div>
                          <div className="field-value element-container">
                            {resource.styles.length === 0 ? (
                              <span>-</span>
                            ) : (
                              resource.styles.map((item, i) => {
                                return (
                                  <span key={i}>
                                    {item.stylesLabel}
                                    {i < resource.styles.length - 1 ? "," : ""}
                                  </span>
                                );
                              })
                            )}
                          </div>
                          <div className="field-title">Mode</div>
                          <div className="field-value element-container">
                            {resource.modes.length === 0 ? (
                              <span>-</span>
                            ) : (
                              resource.modes.map((item, i) => {
                                return (
                                  <span key={i}>
                                    {item.modesLabel}
                                    {i < resource.modes.length - 1 ? "," : ""}
                                  </span>
                                );
                              })
                            )}
                          </div>
                          <div className="field-title">Canonical Regime</div>
                          <div className="field-value element-container">
                            {resource.canonicalRegimes.length === 0 ? (
                              <span>-</span>
                            ) : (
                              resource.canonicalRegimes.map((item, i) => {
                                return (
                                  <span key={i}>
                                    {item.canonicalRegimesLabel}
                                    {i < resource.canonicalRegimes.length - 1
                                      ? ","
                                      : ""}
                                  </span>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {togglePostliteraryUse && op === 1 && (
                      <div className="resource-section-content">
                        <div className="section-content-info">
                          <div className="form-block-6 w-form">
                            <div className="field-title">Strategies</div>
                            <SelectMultiple
                              optionList={strategiesList}
                              selectedOps={resourceEdit.strategies.map(
                                (item) => ({
                                  id: item.strategiesValue,
                                  name: item.strategiesLabel,
                                })
                              )}
                              toggle={togglePostliteraryUseStrategies}
                              handleToggle={() => {
                                toggleStrategies();
                              }}
                              updateResource={(op) => {
                                updateStrategiesResource(op);
                              }}
                            ></SelectMultiple>
                            <div className="field-title">Style</div>
                            <SelectMultiple
                              optionList={stylesList}
                              selectedOps={resourceEdit.styles.map((item) => ({
                                id: item.stylesValue,
                                name: item.stylesLabel,
                              }))}
                              toggle={togglePostliteraryUseStyle}
                              handleToggle={() => {
                                toggleStyle();
                              }}
                              updateResource={(op) => {
                                updateStylesResource(op);
                              }}
                            ></SelectMultiple>
                            <div className="field-title">Mode</div>
                            <SelectMultiple
                              optionList={modesList}
                              toggle={togglePostliteraryUseMode}
                              selectedOps={resourceEdit.modes.map((item) => ({
                                id: item.modesValue,
                                name: item.modesLabel,
                              }))}
                              handleToggle={() => {
                                toggleMode();
                              }}
                              updateResource={(op) => {
                                updateModesResource(op);
                              }}
                            ></SelectMultiple>
                            <div className="field-title">Canonical Regime</div>
                            <SelectMultiple
                              optionList={canonicalRegimesList}
                              selectedOps={resourceEdit.canonicalRegimes.map(
                                (item) => ({
                                  id: item.canonicalRegimesValue,
                                  name: item.canonicalRegimesLabel,
                                })
                              )}
                              toggle={togglePostliteraryUseCanonicalRegime}
                              handleToggle={() => {
                                toggleCanonicalRegime();
                              }}
                              updateResource={(op) => {
                                updateCanonicalRegimesResource(op);
                              }}
                            ></SelectMultiple>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {op === 1 && (
                    <div className="resource-section hide-section">
                      <div
                        className="resource-section-title"
                        onClick={() => {
                          setToggleMetadataEdit(!toggleMetadataEdit);
                        }}
                      >
                        <img
                          src={
                            toggleMetadataEdit
                              ? "images/close-section.svg"
                              : "images/open-section.svg"
                          }
                          height="20"
                          alt="Icon"
                          className="resource-section-icon"
                        ></img>
                        <div className="span-section-title">Metadata</div>
                      </div>
                      {toggleMetadataEdit && op === 1 && (
                        <div className="resource-section-content">
                          <div className="section-content-info">
                            <div className="resource-tag-container">
                              <div className="form-block-4 w-form">
                                <div className="field-title">
                                  Upload Country
                                </div>
                                <select
                                  id="field-upload-country"
                                  className="select-field select-extra w-select"
                                  value={resourceEdit.uploadCountryValue}
                                  onChange={(e) => {
                                    setResourceEdit({
                                      ...resourceEdit,
                                      uploadCountryValue: e.target.value,
                                    });
                                  }}
                                >
                                  {countriesList.map((option, key) => (
                                    <option key={key} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-block-4 w-form">
                                <div className="field-title">
                                  Upload Location
                                </div>
                                <input
                                  id="uploadLocation"
                                  type="text"
                                  className="input-field-value w-input"
                                  maxLength="100"
                                  value={resourceEdit.uploadLocation}
                                  onChange={(e) => {
                                    setResourceEdit({
                                      ...resourceEdit,
                                      uploadLocation: e.target.value,
                                    });
                                  }}
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="resource-section">
                    <div
                      className="resource-section-title"
                      onClick={() => {
                        setToggleAdditionalInfo(!toggleAdditionalInfo);
                      }}
                    >
                      <img
                        src={
                          toggleAdditionalInfo
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="20"
                        alt="Icon"
                        className="resource-section-icon"
                      ></img>
                      <div className="span-section-title">Additional Info</div>
                    </div>
                    {toggleAdditionalInfo && op === 0 && (
                      <div className="resource-section-content">
                        <div className="section-content-info">
                          <div className="field-title">Comments</div>
                          <div
                            className="field-value comment-field"
                            dangerouslySetInnerHTML={{
                              __html:
                                resource.comments === ""
                                  ? "<span>-</span>"
                                  : resource.comments,
                            }}
                          >
                            {/* {resource.comments === "" ? "-" : resource.comments} */}
                          </div>
                          {resource.relatedResources.length > 0 && (
                            <div>
                              <div className="field-title">
                                Related Resources
                              </div>
                              <div className="related-resources-container">
                                {resource.relatedResources.map((e, i) => {
                                  return (
                                    <Card
                                      resource={e}
                                      key={i}
                                      url={"?resourceId="}
                                    ></Card>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {toggleAdditionalInfo && op === 1 && (
                      <div className="resource-section-content">
                        <div className="section-content-info">
                          <div className="resource-tag-container">
                            <div className="form-block-4 w-form">
                              {resourceTagsEdit.length > 0 && (
                                <div>
                                  <div className="field-title">
                                    Tags ({resourceTagsEdit.length})
                                  </div>
                                  <div className="resource-tags-container-info">
                                    {resourceTagsEdit.map((e, i) => {
                                      return (
                                        <div className="tag-info" key={i}>
                                          <div className="field-title span-resource-version">
                                            {e.tag}
                                          </div>
                                          <img
                                            src="images/delete.svg"
                                            alt="Icon"
                                            className="image-10"
                                            onClick={async () => {
                                              await deleteTag(e.idTag);
                                            }}
                                          ></img>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                              <div className="field-title">Add tag</div>
                              <div className="add-tag-container">
                                <input
                                  type="text"
                                  className="input-field-value w-input"
                                  maxLength="25"
                                  id="tag"
                                  value={newTag}
                                  onChange={async (e) => {
                                    setNewTag(e.target.value);
                                    await getTagSuggestions(e.target.value);
                                  }}
                                ></input>
                                <div className="add-tag-img-container">
                                  <img
                                    src="images/add.svg"
                                    alt="Icon"
                                    className="image-9"
                                    onClick={async () => {
                                      await addTag();
                                    }}
                                  ></img>
                                </div>
                              </div>
                              <div className="suggestion-container">
                                <img src="images/info.svg" alt="icon"></img>
                                <div className="field-title suggestion-container-span">
                                  Suggestions ({tagSuggestionList.length}
                                  ):
                                </div>
                                <div className="suggestion-container-list">
                                  {tagSuggestionList.length > 0 &&
                                    tagSuggestionList.map((e, i) => {
                                      return (
                                        <span
                                          className="suggestion-span"
                                          key={i}
                                          onClick={() => {
                                            setNewTag(e);
                                          }}
                                        >
                                          {e}
                                          {i === tagSuggestionList.length - 1
                                            ? ""
                                            : ", "}
                                        </span>
                                      );
                                    })}
                                  {tagSuggestionList.length === 0 && (
                                    <span className="no-suggestion-span">
                                      No suggestions found.
                                    </span>
                                  )}
                                </div>
                              </div>

                              {relatedResources.length > 0 && (
                                <div>
                                  <div className="field-title">
                                    Related Resources ({relatedResources.length}
                                    /6)
                                  </div>
                                  <div className="resource-tags-container-info">
                                    {relatedResources.map((e, i) => {
                                      return (
                                        <div className="tag-info" key={i}>
                                          <div className="field-title span-resource-version">
                                            {e}
                                          </div>
                                          <img
                                            src="images/delete.svg"
                                            alt="Icon"
                                            className="image-10"
                                            onClick={async () => {
                                              await deleteRelatedResource(e);
                                            }}
                                          ></img>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                              <div className="field-title">
                                Add related resource
                              </div>
                              <div className="add-tag-container">
                                <input
                                  type="text"
                                  className="input-field-value w-input"
                                  id="related-resource"
                                  value={newRelatedResource}
                                  onChange={async (e) => {
                                    setNewRelatedResource(e.target.value);
                                    await getRelatedResourceSuggestions(
                                      e.target.value
                                    );
                                  }}
                                ></input>
                                <div className="add-tag-img-container">
                                  <img
                                    src="images/add.svg"
                                    alt="Icon"
                                    className="image-9"
                                    onClick={() => {
                                      addRelatedResource();
                                    }}
                                  ></img>
                                </div>
                              </div>
                              <div className="suggestion-container">
                                <img src="images/info.svg" alt="icon"></img>
                                <div className="field-title suggestion-container-span">
                                  Suggestions (
                                  {relatedResourceSuggestionList.length}
                                  ):
                                </div>
                                <div className="suggestion-container-list">
                                  {relatedResourceSuggestionList.length > 0 &&
                                    relatedResourceSuggestionList.map(
                                      (e, i) => {
                                        return (
                                          <span
                                            className="suggestion-span"
                                            key={i}
                                            onClick={() => {
                                              setNewRelatedResource(
                                                e.referenceId + " - " + e.title
                                              );
                                            }}
                                          >
                                            {e.referenceId + " - " + e.title}
                                            {i ===
                                            relatedResourceSuggestionList.length -
                                              1
                                              ? ""
                                              : ", "}
                                          </span>
                                        );
                                      }
                                    )}
                                  {relatedResourceSuggestionList.length ===
                                    0 && (
                                    <span className="no-suggestion-span">
                                      No suggestions found.
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-block-5 w-form">
                              <div className="field-title">
                                Comments (Max. 2000 characters)
                              </div>
                              <RichTextEditor
                                value={resourceEdit.comments}
                                onChange={(e) => {
                                  setResourceEdit({
                                    ...resourceEdit,
                                    comments: e,
                                  });
                                }}
                              ></RichTextEditor>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
