import React, { useState } from "react";
import Button from "../components/Button";
import "../css/Login.css";
import { post } from "../js/post";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");

  const onLogin = async () => {
    if (username !== "" || password !== "") {
      const response = await post("Login", {
        username: username,
        password: password,
      });
      if (response.error !== "") {
        setErrorLogin(response.error);
      } else {
        localStorage.setItem("userData", JSON.stringify(response));
        setErrorLogin("");
        window.location = "/";
      }
    }
  };

  return (
    <div className="login">
      <div className="login-header">
        <div className="login-header-icons" style={{ gap: "2px" }}>
          <img src="images/logo1.png"></img>
          <img src="images/logo2.png"></img>
          <img src="images/logo3.png"></img>
        </div>
        <div
          className="header-title"
          onClick={() => {
            window.location = "/";
          }}
        >
          <div className="text-block">
            <strong className="bold-text">Branding </strong>the{" "}
            <strong className="bold-text-2">Canon</strong>
          </div>
        </div>
      </div>
      <div className="login-content">
        <div className="login-box">
          <div className="login-box-header">
            <div className="text-block-2">Login</div>
          </div>
          <div className="login-box-container">
            <div className="form-block w-form">
              <label className="field-label">Username</label>
              <input
                type="text"
                className="text-field-4 w-input"
                maxLength="60"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter" && username !== "" && password !== "") {
                    await onLogin();
                  }
                }}
              ></input>
              <label className="field-label">Password</label>
              <input
                type="password"
                className="text-field-4 w-input"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter" && username !== "" && password !== "") {
                    await onLogin();
                  }
                }}
              ></input>
              {errorLogin !== "" && (
                <div className="error-login-container">
                  <span>{errorLogin}</span>
                  <img
                    src="images/close-message.svg"
                    alt="Icon"
                    onClick={() => {
                      setErrorLogin("");
                    }}
                  ></img>
                </div>
              )}
              <div className="button-login-container">
                <Button
                  text="LOGIN"
                  onPressButton={() => {
                    onLogin();
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-footer"></div>
    </div>
  );
}
