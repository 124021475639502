import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import NavigationBar from "../components/NavigationBar";
import ResourceCard from "../components/ResourceCard";
import { LoadingInfo } from "../components/LoadingInfo";
import { post } from "../js/post";
import { reviewOptions } from "../js/selectData";
import { getToken } from "../js/userData";

export default function AdminPanel() {
  const [togglePanel, setTogglePanel] = useState(true);
  const [sourcesList, setSourcesList] = useState([
    { categoryId: "", name: "Sources (All)" },
  ]);
  const [strategiesList, setStrategiesList] = useState([
    { id: "", name: "Strategies (All)" },
  ]);
  const [stylesList, setStylesList] = useState([
    { id: "", name: "Styles (All)" },
  ]);
  const [referredAuthorsList, setReferredAuthorsList] = useState([
    { id: "", name: "Referred Authors (All)" },
  ]);
  const [modesList, setModesList] = useState([{ id: "", name: "Modes (All)" }]);
  const [canonicalRegimesList, setCanonicalRegimesList] = useState([
    { id: "", name: "Canonical Regimes (All)" },
  ]);
  const [reviewersList, setReviewersList] = useState([
    { id: "", name: "Reviewers (All)" },
  ]);
  const [resourcesList, setResourcesList] = useState([]);
  const [filterReviewerValue, setFilterReviewerValue] = useState("");
  const [filterReviewedStateValue, setFilterReviewedStateValue] = useState("0");
  const [filterStrategiesValue, setFilterStrategiesValue] = useState("");
  const [filterStyleValue, setFilterStyleValue] = useState("");
  const [filterCanonicalRegimeValue, setFilterCanonicalRegimeValue] =
    useState("");
  const [filterModeValue, setFilterModeValue] = useState("");
  const [filterSourceValue, setFilterSourceValue] = useState("");
  const [filterReferredAuthorValue, setFilterReferredAuthorValue] =
    useState("");
  const [loadingResourcesList, setLoadingResourcesList] = useState(false);

  const getResourcesAdmin = async () => {
    setLoadingResourcesList(true);
    const response = await post("GetResourcesAdmin", {
      token: getToken(),
      filterReviewer: filterReviewerValue,
      filterReviewedState: filterReviewedStateValue,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterReferredAuthor: filterReferredAuthorValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
    });
    setResourcesList(response);
    setLoadingResourcesList(false);
  };

  const getSourcesListSelect = async () => {
    const response = await post("ListSourcesSelect", {
      type: "",
    });
    response.unshift({
      categoryId: "",
      name: "Sources (All)",
    });
    setSourcesList(response);
  };

  const getStrategiesListSelect = async () => {
    const response = await post("ListStrategiesSelect", {});
    response.unshift({
      id: "",
      name: "Strategies (All)",
    });
    setStrategiesList(response);
  };

  const getStylesListSelect = async () => {
    const response = await post("ListStylesSelect", {});
    response.unshift({
      id: "",
      name: "Styles (All)",
    });
    setStylesList(response);
  };

  const getModesListSelect = async () => {
    const response = await post("ListModesSelect", {});
    response.unshift({
      id: "",
      name: "Modes (All)",
    });
    setModesList(response);
  };

  const getCanonicalRegimesListSelect = async () => {
    const response = await post("ListCanonicalRegimesSelect", {});
    response.unshift({
      id: "",
      name: "Canonical Regimes (All)",
    });
    setCanonicalRegimesList(response);
  };

  const getReviewersListSelect = async () => {
    const response = await post("ListAdminsSelect", {
      token: getToken(),
    });
    response.unshift({
      id: "",
      name: "Reviewers (All)",
    });
    setReviewersList(response);
  };

  const getReferredAuthorsListSelect = async () => {
    const response = await post("ListReferredAuthorsSelect", {});
    let list = [];
    response.map((item) => {
      return list.push({
        id: item,
        name: item,
      });
    });
    list.unshift({ id: "", name: "Referred Authors (All)" });
    list = list.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    setReferredAuthorsList(list);
  };

  const resetFilters = () => {
    setFilterModeValue("");
    setFilterReferredAuthorValue("");
    setFilterSourceValue("");
    setFilterStrategiesValue("");
    setFilterStyleValue("");
    setFilterReviewerValue("");
    setFilterReviewedStateValue("0");
  };

  useEffect(() => {
    getResourcesAdmin();
    getSourcesListSelect();
    getStrategiesListSelect();
    getStylesListSelect();
    getModesListSelect();
    getCanonicalRegimesListSelect();
    getReferredAuthorsListSelect();
    getReviewersListSelect();
  }, [
    filterModeValue,
    filterReferredAuthorValue,
    filterReviewedStateValue,
    filterReviewerValue,
    filterSourceValue,
    filterStrategiesValue,
    filterStyleValue,
    filterCanonicalRegimeValue,
  ]);

  return (
    <div className="admin-panel">
      <div className="admin-content-section">
        <div className="admin-panel-header-container">
          <NavigationBar></NavigationBar>
          <div
            className="admin-panel-header-title"
            onClick={() => {
              window.location = "/";
            }}
          >
            <div className="text-block-4">
              <strong className="bold-text-3">Branding</strong> the{" "}
              <strong className="bold-text-4">Canon</strong>
            </div>
          </div>
        </div>
        <div className="admin-panel-content">
          <div className="admin-panel-title">
            <div className="title-container">
              <div className="text-block-8">
                Admin <strong>Panel</strong>
              </div>
            </div>
          </div>
          <div className="admin-panel-filter-bar">
            <div className="span-button span-options-title">FILTERS</div>
            <div className="filter-options-container">
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-4"
                    className="select-field-3 w-select"
                    value={filterReviewedStateValue}
                    onChange={(e) => {
                      setFilterReviewedStateValue(e.target.value);
                    }}
                  >
                    {reviewOptions.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-4"
                    className="select-field-3 w-select"
                    value={filterReviewerValue}
                    onChange={(e) => {
                      setFilterReviewerValue(e.target.value);
                    }}
                  >
                    {reviewersList.map((option, key) => (
                      <option key={key} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-4"
                    className="select-field-3 w-select"
                    value={filterReferredAuthorValue}
                    onChange={(e) => {
                      setFilterReferredAuthorValue(e.target.value);
                    }}
                  >
                    {referredAuthorsList.map((option, key) => (
                      <option key={key} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-4"
                    className="select-field-3 w-select"
                    value={filterSourceValue}
                    onChange={(e) => {
                      setFilterSourceValue(e.target.value);
                    }}
                  >
                    {sourcesList.map((option, key) => (
                      <option key={key} value={option.categoryId}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-4"
                    className="select-field-3 w-select"
                    value={filterStrategiesValue}
                    onChange={(e) => {
                      setFilterStrategiesValue(e.target.value);
                    }}
                  >
                    {strategiesList.map((option, key) => (
                      <option key={key} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-3"
                    className="select-field-3 w-select"
                    value={filterStyleValue}
                    onChange={(e) => {
                      setFilterStyleValue(e.target.value);
                    }}
                  >
                    {stylesList.map((option, key) => (
                      <option key={key} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-2"
                    className="select-field-3 w-select"
                    value={filterModeValue}
                    onChange={(e) => {
                      setFilterModeValue(e.target.value);
                    }}
                  >
                    {modesList.map((option, key) => (
                      <option key={key} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="filter-op-container  filter-op-container-extra">
                <div className="form-block-10 w-form">
                  <select
                    id="field-2"
                    className="select-field-3 w-select"
                    value={filterCanonicalRegimeValue}
                    onChange={(e) => {
                      setFilterCanonicalRegimeValue(e.target.value);
                    }}
                  >
                    {canonicalRegimesList.map((option, key) => (
                      <option key={key} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className="reset-filter-button"
              title="Reset filters"
              onClick={() => {
                resetFilters();
                getResourcesAdmin();
              }}
            >
              <img src="images/reset.svg"></img>
            </div>
          </div>

          <div className="user-profile-box admin-panel-box">
            <div className="rcb-right-panel rcb-right-panel-extra">
              <div className="admin-panel-section">
                <div
                  className="admin-panel-section-title"
                  onClick={() => {
                    setTogglePanel(!togglePanel);
                  }}
                >
                  <img
                    src={
                      togglePanel
                        ? "images/close-section.svg"
                        : "images/open-section.svg"
                    }
                    height="15"
                    width="15"
                    alt="Icon"
                    className="admin-panel-section-icon"
                  ></img>
                  <div className="span-section-title">
                    Last Resources Provided{" "}
                    {resourcesList.length > 0
                      ? "(" + resourcesList.length + ")"
                      : ""}
                  </div>
                </div>
                {togglePanel && (
                  <div className="admin-panel-section-content">
                    <div className="admin-panel-last-resources-provided">
                      {loadingResourcesList && (
                        <LoadingInfo title="Loading admin panel..."></LoadingInfo>
                      )}
                      {!loadingResourcesList && (
                        <div className="admin-panel-last-resources-provided-container">
                          {resourcesList.length > 0 &&
                            resourcesList.map((item, i) => (
                              <ResourceCard
                                resource={item}
                                key={i}
                              ></ResourceCard>
                            ))}
                          {resourcesList.length === 0 && (
                            <div className="no-resources-info">
                              <span>No resources</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
