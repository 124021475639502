import React from "react";
import "../css/LoadingInfo.css";

export function LoadingInfo(props) {
  return (
    <div className="loading-content">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span className="loading-title">{props.title}</span>
    </div>
  );
}
