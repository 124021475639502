import React from "react";
import SocialButton from "./SocialButton";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="main-footer">
      <div className="main-footer-info">
        <div className="span-title">
          <strong className="bold-text-5">Branding</strong> the{" "}
          <strong className="bold-text-6">Canon</strong>
        </div>
        <div className="main-contact-info">
          <div className="span-contact">CONTACT</div>
          <div className="span-email">info@brandingthecanon.com</div>
        </div>
        <div className="main-footer-icons">
          <SocialButton
            img={"../images/x.svg"}
            url={"https://twitter.com/post_literature"}
          ></SocialButton>
          <SocialButton
            img={"../images/instagram.svg"}
            url={"https://www.instagram.com/post_literature/"}
          ></SocialButton>
        </div>
      </div>
      <div className="main-footer-developer">
        <a
          href="https://himalayac.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="images/himalaya.png"
            alt="Himalaya Computing logo"
            className="image-4 footer-logo"
          ></img>
        </a>
        <div className="text-block-7">
          <a
            href="https://himalayac.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Developer by Himalaya Computing, {currentYear}
          </a>
        </div>
      </div>
    </div>
  );
}
