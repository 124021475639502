export const reviewOptions = [
  {
    value: "0",
    label: "Not reviewed",
  },
  {
    value: "1",
    label: "Reviewed",
  },
  {
    value: "",
    label: "All",
  },
];

export const userSortingOptions = [
  {
    value: "0",
    label: "Sort by role",
  },
  {
    value: "1",
    label: "Sort by name",
  },
  {
    value: "2",
    label: "Sort by resources",
  },
];
