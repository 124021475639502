import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./screens/Main";
import "./fonts/Raleway/Raleway-Light.ttf";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserProfile from "./screens/UserProfile";
import Login from "./screens/Login";
import AdminPanel from "./screens/AdminPanel";
import Resource from "./screens/Resource";
import Users from "./screens/Users";
import Register from "./screens/Register";
import Tags from "./screens/Tags";
import { isAdmin } from "./js/userData";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/user" element={<UserProfile />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/admin" element={isAdmin() ? <AdminPanel /> : <Main />} />
      <Route path="/users" element={isAdmin() ? <Users /> : <Main />} />
      <Route path="/tags" element={isAdmin() ? <Tags /> : <Main />} />
      <Route path="/resource" element={<Resource />} />
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
