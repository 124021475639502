import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import NavigationBar from "../components/NavigationBar";
import OptionButton from "../components/OptionButton";
import TagCard from "../components/TagCard";
import { LoadingInfo } from "../components/LoadingInfo";
import { post } from "../js/post";
import { getToken } from "../js/userData";
import "../css/Users.css";

export default function Tags() {
  const [tagList, setTagList] = useState([]);
  const [loadingTagList, setLoadingTagList] = useState(false);
  const [sortApperancesValue, setSortApperancesValue] = useState(false);
  const [sortAlphabeticallyValue, setSortAlphabeticallyValue] = useState(true);
  const [clearedTagList, setClearedTagList] = useState([]);

  const getTagList = async () => {
    setLoadingTagList(true);
    const response = await post("LoadTags", {
      token: getToken(),
    });
    setLoadingTagList(false);
    setSortAlphabeticallyValue(false);
    sortTagsAlphabetically(response);
  };

  const sortTagsAlphabetically = (list) => {
    const normalizeVowel = (char) => {
      switch (char) {
        case "Á":
        case "á":
          return "a";
        case "À":
        case "à":
          return "a";
        case "Ä":
        case "ä":
          return "a";
        case "Â":
        case "â":
          return "a";
        case "É":
        case "é":
          return "e";
        case "È":
        case "è":
          return "e";
        case "Ë":
        case "ë":
          return "e";
        case "Ê":
        case "ê":
          return "e";
        case "Í":
        case "í":
          return "i";
        case "Ì":
        case "ì":
          return "i";
        case "Ï":
        case "ï":
          return "i";
        case "Î":
        case "î":
          return "i";
        case "Ó":
        case "ó":
          return "o";
        case "Ò":
        case "ò":
          return "o";
        case "Ö":
        case "ö":
          return "o";
        case "Ô":
        case "ô":
          return "o";
        case "Ú":
        case "ú":
          return "u";
        case "Ù":
        case "ù":
          return "u";
        case "Ü":
        case "ü":
          return "u";
        case "Û":
        case "û":
          return "u";
        case "Ý":
        case "ý":
          return "y";
        case "Ỳ":
        case "ỳ":
          return "y";
        case "Ÿ":
        case "ÿ":
          return "y";
        case "Ỹ":
        case "ỹ":
          return "y";
        default:
          return char;
      }
    };

    const isAlphabetic = (char) => {
      return /[a-zA-ZÀ-ÖØ-öø-ÿ]/.test(char);
    };

    const sortedTagList = [...list].sort((a, b) => {
      const aFirstChar = normalizeVowel(a.name.charAt(0)).toLowerCase();
      const bFirstChar = normalizeVowel(b.name.charAt(0)).toLowerCase();

      if (isAlphabetic(aFirstChar) && !isAlphabetic(bFirstChar)) {
        return -1;
      } else if (!isAlphabetic(aFirstChar) && isAlphabetic(bFirstChar)) {
        return 1;
      } else {
        return aFirstChar.localeCompare(bFirstChar);
      }
    });

    setTagList(sortedTagList);
    setClearedTagList(sortedTagList);
  };

  const sortTagsByNumberOfAppearances = () => {
    const sortedTagList = [...tagList].sort((a, b) =>
      !sortApperancesValue
        ? b.resources.length - a.resources.length
        : a.resources.length - b.resources.length
    );
    setTagList(sortedTagList);
  };

  useEffect(() => {
    getTagList();
  }, []);

  useEffect(() => {
    setTagList(tagList.reverse());
  }, [sortAlphabeticallyValue]);

  return (
    <div className="admin-panel">
      <div className="admin-content-section">
        <div className="admin-panel-header-container">
          <NavigationBar></NavigationBar>
          <div
            className="admin-panel-header-title"
            onClick={() => {
              window.location = "/";
            }}
          >
            <div className="text-block-4">
              <strong className="bold-text-3">Branding</strong> the{" "}
              <strong className="bold-text-4">Canon</strong>
            </div>
          </div>
        </div>
        <div className="admin-panel-content">
          <div className="admin-panel-title">
            <div className="title-container">
              <div className="text-block-8">
                Tag <strong>List</strong>
              </div>
            </div>
          </div>
          <div className="user-profile-option-box">
            <div className="option-button-container">
              <OptionButton
                text={"Sort by number of apperances"}
                icon={
                  !sortApperancesValue
                    ? "/images/sort-1.svg"
                    : "/images/sort-2.svg"
                }
                onPress={() => {
                  setSortApperancesValue(!sortApperancesValue);
                  if (sortAlphabeticallyValue) {
                    setTagList(clearedTagList);
                    setSortAlphabeticallyValue(false);
                  }
                  sortTagsByNumberOfAppearances();
                }}
                disabled={false}
              />
              <OptionButton
                text={"Sort alphabetically"}
                icon={
                  !sortAlphabeticallyValue
                    ? "/images/sort-1.svg"
                    : "/images/sort-2.svg"
                }
                onPress={() => {
                  setSortAlphabeticallyValue(!sortAlphabeticallyValue);
                  if (sortApperancesValue) {
                    setTagList(clearedTagList);
                    setSortApperancesValue(false);
                  }
                }}
                disabled={false}
              />
            </div>
          </div>
          <div className="user-profile-box admin-panel-box">
            <div className="rcb-right-panel rcb-right-panel-extra">
              <div className="admin-panel-section">
                <div className="admin-panel-section-title admin-panel-section-title-extra">
                  <div className="span-section-title">
                    Tag List{" "}
                    {tagList.length > 0 ? "(" + tagList.length + ")" : ""}
                  </div>
                </div>
                <div className="admin-panel-section-content">
                  <div className="admin-panel-last-resources-provided">
                    {loadingTagList && (
                      <LoadingInfo title="Loading tags..."></LoadingInfo>
                    )}
                    {!loadingTagList && (
                      <div className="admin-panel-last-resources-provided-container">
                        {tagList.length > 0 &&
                          tagList.map((item, i) => (
                            <TagCard tag={item} key={i}></TagCard>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
