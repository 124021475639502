export let convertToLocalDate = (date) => {
  const currentD = date.toJSON().split("T")[0].replaceAll("-", "/");
  let currentH = date.getHours();
  if (currentH < 10) {
    currentH = "0" + currentH;
  }
  let currentM = date.getMinutes();
  if (currentM < 10) {
    currentM = "0" + currentM;
  }
  let currentS = date.getSeconds();
  if (currentS < 10) {
    currentS = "0" + currentS;
  }
  return currentD + " " + currentH + ":" + currentM + ":" + currentS;
};

export const dateRangeOptionsList = [
  { id: "", name: "Date Range (All)" },
  { id: "0", name: "Today" },
  { id: "1", name: "Yesterday" },
  { id: "2", name: "Last 7 days" },
  { id: "3", name: "Last 30 days" },
  { id: "4", name: "Last 90 days" },
  { id: "5", name: "Last 365 days" },
];

export const tagsToShowOptionsList = [
  { id: "0", name: "5" },
  { id: "1", name: "10" },
  { id: "2", name: "15" },
  { id: "3", name: "20" },
  { id: "4", name: "25" },
  { id: "5", name: "30" },
];

export const mapResources = [
  {
    resourceId: 1,
    resourceImage:
      "https://btc-api.brandingthecanon.com/uploads/6/608/20240527234648.jpeg",
    type: "",
    title: "BASE DE COPOS DE PESSOA - SINTO-ME NASCIDO",
    referredAuthor: "FERNANDO PESSOA-ALBERTO CAEIRO",
    username: "IOLANDA OGANDO GONZÁLEZ",
    rights: "Copyright",
    latitude: 40.4637,
    longitude: -3.7492,
  },
  {
    resourceId: 2,
    resourceImage:
      "https://btc-api.brandingthecanon.com/uploads/4/421/20240419180637.jpg",
    type: "",
    title: "APARICIÓN DE DON QUIJOTE EN CÓMIC DE ASTÉRIX",
    referredAuthor: "MIGUEL DE CERVANTES",
    username: "Admin 1",
    rights: "Copyleft",
    latitude: 41.3322,
    longitude: -3.5432,
  },
];
