import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import NavigationBar from "../components/NavigationBar";
import Button from "../components/Button";
import Modal from "react-modal";
import { post } from "../js/post";
import "../css/UserProfile.css";
import "../css/Register.css";

const currentUser = {
  email: "",
  username: "",
  password: "",
  passwordRep: "",
  name: "",
  lastName: "",
  location: "",
  country: "247",
};

export default function Register() {
  const [personalInformationPanel, setPersonalInformationPanel] =
    useState(true);
  const [user, setUser] = useState(currentUser);
  const [countriesList, setCountriesList] = useState([]);
  const [registerOK, setRegisterOK] = useState(false);
  const [fieldsError, setFieldsError] = useState({
    name: false,
    lastName: false,
    email: false,
    username: false,
    password1: false,
    password2: false,
    location: false,
    errorMsg: "",
  });

  const getCountriesList = async () => {
    const response = await post("ListCountriesSelect", {});
    setCountriesList(response);
  };

  const validateUserFields = async () => {
    if (
      user.name === "" ||
      user.lastName === "" ||
      user.username === "" ||
      user.email === "" ||
      user.password === "" ||
      user.passwordRep === "" ||
      user.location === ""
    ) {
      setFieldsError({
        name: user.name === "",
        lastName: user.lastName === "",
        email: user.email === "" || !validateEmail(),
        username: user.username === "",
        password1: user.password === "",
        password2: user.passwordRep === "",
        location: user.location === "",
        errorMsg: "All fields are required.",
      });
    } else if (user.password !== user.passwordRep) {
      setFieldsError({
        ...fieldsError,
        password1: true,
        password2: true,
        errorMsg: "Passwords do not match.",
      });
      return;
    } else {
      setFieldsError({
        name: false,
        lastName: false,
        email: false,
        username: false,
        password1: false,
        password2: false,
        location: false,
        errorMsg: "",
      });
      await registerUser();
    }
  };

  const registerUser = async () => {
    const response = await post("RegisterUser", {
      email: user.email,
      username: user.username,
      password: user.password,
      name: user.name,
      lastName: user.lastName,
      location: user.location,
      country: user.country,
    });
    if (response.error !== "") {
      setFieldsError({
        name: false,
        lastName: false,
        password1: false,
        password2: false,
        location: false,
        username: true,
        email: true,
        errorMsg: response.error,
      });
    } else {
      setRegisterOK(true);
      setFieldsError({
        name: false,
        lastName: false,
        email: false,
        username: false,
        password1: false,
        password2: false,
        location: false,
        errorMsg: "",
      });
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(user.email);
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  return (
    <div className="user-profile">
      <div className="user-profile-contain">
        <div className="user-profile-header">
          <NavigationBar></NavigationBar>
          <div
            className="user-profile-header-title"
            onClick={() => {
              window.location = "/";
            }}
          >
            <div className="text-block-4">
              <strong className="bold-text-3">Branding</strong> the{" "}
              <strong className="bold-text-4">Canon</strong>
            </div>
          </div>
        </div>
        <div className="user-profile-content">
          <div className="user-profile-title">
            <div className="title-container">
              <div className="text-block-8">
                <strong>Register</strong>
              </div>
            </div>
          </div>
          <div className="user-profile-option-box">
            <div className="span-button span-options-title">CREATE ACCOUNT</div>
          </div>
          <div className="user-profile-box">
            <div className="rcb-right-panel rcb-right-panel-extra">
              <div className="user-profile-section">
                <div
                  className="user-profile-section-title"
                  onClick={() => {
                    setPersonalInformationPanel(!personalInformationPanel);
                  }}
                >
                  <img
                    src={
                      personalInformationPanel
                        ? "images/close-section.svg"
                        : "images/open-section.svg"
                    }
                    height="15"
                    width="15"
                    alt="Open Close"
                    className="user-profile-section-icon"
                  ></img>
                  <div className="span-section-title">Personal Information</div>
                </div>
                {personalInformationPanel && (
                  <div className="user-profile-section-content register-user-content">
                    <div className="user-profile-section-content-info">
                      {fieldsError.errorMsg !== "" && (
                        <div className="error-login-container error-container">
                          <span>{fieldsError.errorMsg}</span>
                          <img
                            src="images/close-message.svg"
                            alt="Icon"
                            onClick={() => {
                              setFieldsError({
                                name: false,
                                lastName: false,
                                email: false,
                                username: false,
                                password1: false,
                                password2: false,
                                location: false,
                                errorMsg: "",
                              });
                            }}
                          ></img>
                        </div>
                      )}
                      <div className="data-field-container">
                        <div className="data-info-container">
                          <div className="user-field-label">Name</div>
                          <input
                            id="name"
                            type="text"
                            className={`input-field-value w-input ${
                              fieldsError.name ? "input-error" : ""
                            }`}
                            maxLength="100"
                            value={user.name}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                name: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div className="data-info-container">
                          <div className="user-field-label">Last Name</div>
                          <input
                            id="lastname"
                            type="text"
                            className={`input-field-value w-input ${
                              fieldsError.lastName ? "input-error" : ""
                            }`}
                            maxLength="100"
                            value={user.lastName}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                lastName: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="data-field-container">
                        <div className="data-info-container">
                          <div className="user-field-label">Email</div>
                          <input
                            id="email"
                            type="email"
                            className={`input-field-value w-input ${
                              fieldsError.email ? "input-error" : ""
                            }`}
                            maxLength="100"
                            value={user.email}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                email: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div className="data-info-container">
                          <div className="user-field-label">Username</div>
                          <input
                            id="username"
                            type="text"
                            className={`input-field-value w-input ${
                              fieldsError.username ? "input-error" : ""
                            }`}
                            maxLength="60"
                            value={user.username}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                username: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="data-field-container">
                        <div className="data-info-container">
                          <div className="user-field-label">Password</div>
                          <input
                            id="password-1"
                            type="password"
                            className={`input-field-value w-input ${
                              fieldsError.password1 ? "input-error" : ""
                            }`}
                            maxLength="100"
                            value={user.password}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                password: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div className="data-info-container">
                          <div className="user-field-label">
                            Repeat Password
                          </div>
                          <input
                            id="password-2"
                            type="password"
                            className={`input-field-value w-input ${
                              fieldsError.password2 ? "input-error" : ""
                            }`}
                            maxLength="100"
                            value={user.passwordRep}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                passwordRep: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="data-field-container">
                        <div className="data-info-container">
                          <div className="user-field-label">Location</div>
                          <input
                            id="location"
                            type="text"
                            className={`input-field-value w-input ${
                              fieldsError.location ? "input-error" : ""
                            }`}
                            maxLength="100"
                            value={user.location}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                location: e.target.value,
                              });
                            }}
                          ></input>
                        </div>
                        <div className="data-info-container">
                          <div className="user-field-label">Country</div>
                          <select
                            id="country"
                            className="select-field select-extra w-select"
                            value={user.country}
                            onChange={(e) => {
                              setUser({
                                ...user,
                                country: e.target.value,
                              });
                            }}
                          >
                            {countriesList.map((option, key) => (
                              <option key={key} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="user-profile-button-container">
                        <Button
                          text="REGISTER"
                          onPressButton={async () => {
                            await validateUserFields();
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
      <Modal isOpen={registerOK} className="modal">
        <div className="message-box-container modal-box">
          <div className="message-box-question-container">
            <img src="images/warning.svg" alt="icon"></img>
            <span>
              User registered <span className="bold-text">successfully</span> .
            </span>
          </div>
          <div className="message-box-buttons-container">
            <div
              className="button-18"
              role="button"
              onClick={async () => {
                window.location = "/login";
              }}
            >
              {"OK"}
            </div>
            {/*             <div
              className="button-18"
              role="button"
              onClick={() => {
                setBanUserOp(false);
              }}
            >
              {"CANCEL"}
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
}
