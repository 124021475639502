import React, { useState } from "react";
import "../css/Button.css";

export default function Button(props) {
  const [text] = useState(props.text);
  return (
    <div className="button" onClick={props.onPressButton}>
      <span>{text}</span>
    </div>
  );
}
