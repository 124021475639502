export const generateSourceStructure = (sourceValue) => {
  let sourceStructure = {};
  switch (sourceValue) {
    case "D_Advertisement":
      sourceStructure = {
        id: "",
        sponsor: "",
        description: "",
        year: "",
      };
      break;
    case "D_Banner":
      sourceStructure = { id: "", publisher: "", description: "", year: "" };
      break;
    case "D_BlogForumPost":
      sourceStructure = {
        id: "",
        author: "",
        blogName: "",
        date: "",
        year: "",
        description: "",
      };
      break;
    case "D_Database":
      sourceStructure = {
        id: "",
        databaseName: "",
        photographer: "",
        type: "",
        language: "",
        year: "",
        description: "",
      };
      break;
    case "D_Doodle":
      sourceStructure = { id: "", date: "", description: "", year: "" };
      break;
    case "P_ChirographyDisplay":
      sourceStructure = {
        id: "",
        sponsor: "",
        description: "",
        address: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_ChirographySkin":
      sourceStructure = {
        id: "",
        description: "",
        address: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "D_AnimatedGIF":
    case "D_Meme":
    case "D_Other":
      sourceStructure = {
        id: "",
        description: "",
        year: "",
      };
      break;
    case "P_Other":
      sourceStructure = {
        id: "",
        description: "",
        address: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Poster":
      sourceStructure = {
        id: "",
        author: "",
        sponsor: "",
        description: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Decorative":
      sourceStructure = {
        id: "",
        description: "",
        author: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "D_Illustration":
      sourceStructure = {
        id: "",
        author: "",
        year: "",
        description: "",
      };
      break;
    case "D_Magazine":
      sourceStructure = {
        id: "",
        title: "",
        authors: "",
        magazine: "",
        date: "",
        year: "",
        description: "",
      };
      break;
    case "D_Mail":
      sourceStructure = {
        id: "",
        from: "",
        to: "",
        datetime: "",
        description: "",
        year: "",
      };
      break;
    case "D_Music":
      sourceStructure = {
        id: "",
        author: "",
        song: "",
        album: "",
        year: "",
        minute: "",
        platform: "",
        description: "",
      };
      break;
    case "D_Photography":
      sourceStructure = { id: "", photographer: "", year: "", description: "" };
      break;
    case "D_Painting":
      sourceStructure = {
        id: "",
        author: "",
        year: "",
        location: "",
        description: "",
      };
      break;
    case "D_Podcast":
      sourceStructure = {
        id: "",
        programName: "",
        producer: "",
        minute: "",
        platform: "",
        date: "",
        year: "",
        description: "",
      };
      break;
    case "D_Press":
      sourceStructure = {
        id: "",
        title: "",
        authors: "",
        newspaper: "",
        date: "",
        year: "",
        description: "",
      };
      break;
    case "D_Radio":
    case "D_TV":
      sourceStructure = {
        id: "",
        programName: "",
        producer: "",
        minute: "",
        date: "",
        year: "",
        description: "",
      };
      break;
    case "D_SocialMediaPost":
      sourceStructure = {
        id: "",
        author: "",
        datetime: "",
        description: "",
        platform: "",
        year: "",
      };
      break;
    case "D_Video":
      sourceStructure = {
        id: "",
        name: "",
        contentCreator: "",
        description: "",
        minute: "",
        platform: "",
        year: "",
      };
      break;
    case "D_Web":
      sourceStructure = { id: "", owner: "", year: "", description: "" };
      break;
    case "P_Advertisement":
      sourceStructure = {
        id: "",
        sponsor: "",
        description: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
        address: "",
      };
      break;
    case "P_ArtCraft":
      sourceStructure = {
        id: "",
        author: "",
        description: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Article":
      sourceStructure = {
        id: "",
        title: "",
        authors: "",
        journal: "",
        volume: "",
        firstPage: "",
        lastPage: "",
        coordinatesLong: "",
        coordinatesLat: "",
        year: "",
        address: "",
        description: "",
      };
      break;
    case "P_Book":
      sourceStructure = {
        id: "",
        title: "",
        author: "",
        publisher: "",
        year: "",
        page: "",
        coordinatesLong: "",
        coordinatesLat: "",
        address: "",
        description: "",
      };
      break;
    case "P_Brochure":
      sourceStructure = {
        id: "",
        title: "",
        sponsor: "",
        description: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
        address: "",
      };
      break;
    case "P_Clothes":
      sourceStructure = {
        id: "",
        type: "",
        brand: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Currency":
      sourceStructure = {
        id: "",
        description: "",
        originalValue: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Illustration":
      sourceStructure = {
        id: "",
        author: "",
        year: "",
        description: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Magazine":
      sourceStructure = {
        id: "",
        title: "",
        authors: "",
        magazine: "",
        page: "",
        date: "",
        address: "",
        year: "",
        coordinatesLat: "",
        coordinatesLong: "",
        description: "",
      };
      break;
    case "P_Merchandising":
      sourceStructure = {
        id: "",
        description: "",
        brand: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Music":
      sourceStructure = {
        id: "",
        author: "",
        song: "",
        album: "",
        year: "",
        minute: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Performance":
      sourceStructure = {
        id: "",
        title: "",
        artist: "",
        description: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Photography":
      sourceStructure = {
        id: "",
        photographer: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Painting":
      sourceStructure = {
        id: "",
        author: "",
        address: "",
        location: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Postcard":
      sourceStructure = {
        id: "",
        copywriter: "",
        description: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Press":
      sourceStructure = {
        id: "",
        title: "",
        authors: "",
        newspaper: "",
        page: "",
        date: "",
        address: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Radio":
      sourceStructure = {
        id: "",
        programName: "",
        producer: "",
        minute: "",
        date: "",
        address: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Stamp":
      sourceStructure = {
        id: "",
        country: "",
        description: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Statue":
      sourceStructure = {
        id: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
        author: "",
        year: "",
        description: "",
      };
      break;
    case "P_Store":
      sourceStructure = {
        id: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
        type: "",
        year: "",
        description: "",
      };
      break;
    case "P_StreetArt":
      sourceStructure = {
        id: "",
        author: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
        year: "",
        description: "",
      };
      break;
    case "P_TV":
      sourceStructure = {
        id: "",
        programName: "",
        producer: "",
        minute: "",
        date: "",
        address: "",
        year: "",
        coordinatesLong: "",
        coordinatesLat: "",
        description: "",
      };
      break;
    case "P_Video":
      sourceStructure = {
        id: "",
        name: "",
        contentCreator: "",
        description: "",
        minute: "",
        year: "",
        address: "",
        coordinatesLong: "",
        coordinatesLat: "",
      };
      break;
    case "P_Display":
      sourceStructure = {
        id: "",
        sponsor: "",
        description: "",
        address: "",
        year: "",
        coordinatesLat: "",
        coordinatesLong: "",
      };
      break;
    default:
      break;
  }
  return sourceStructure;
};

export const generateSourceComponent = (resourceSource, countriesList) => {
  if (resourceSource.source) {
    let component = "";
    let countryName = "";
    if (resourceSource.contents.country) {
      countryName = countriesList.find(
        (e) => e.id === resourceSource.contents.country
      ).name;
    }
    switch (resourceSource.source) {
      case "P_Book":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Publisher</div>
            <div className="field-value">
              {resourceSource.contents.publisher !== ""
                ? resourceSource.contents.publisher
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Page</div>
            <div className="field-value">
              {resourceSource.contents.page !== ""
                ? resourceSource.contents.page
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Article":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Authors</div>
            <div className="field-value">
              {resourceSource.contents.authors !== ""
                ? resourceSource.contents.authors
                : "-"}
            </div>
            <div className="field-title">Journal</div>
            <div className="field-value">
              {resourceSource.contents.journal !== ""
                ? resourceSource.contents.journal
                : "-"}
            </div>
            <div className="field-title">Volume</div>
            <div className="field-value">
              {resourceSource.contents.volume !== ""
                ? resourceSource.contents.volume
                : "-"}
            </div>
            <div className="field-title">First Page</div>
            <div className="field-value">
              {resourceSource.contents.firstPage !== ""
                ? resourceSource.contents.firstPage
                : "-"}
            </div>
            <div className="field-title">Last Page</div>
            <div className="field-value">
              {resourceSource.contents.lastPage !== ""
                ? resourceSource.contents.lastPage
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_ArtCraft":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Press":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Authors</div>
            <div className="field-value">
              {resourceSource.contents.authors !== ""
                ? resourceSource.contents.authors
                : "-"}
            </div>
            <div className="field-title">Newspaper</div>
            <div className="field-value">
              {resourceSource.contents.newspaper !== ""
                ? resourceSource.contents.newspaper
                : "-"}
            </div>
            <div className="field-title">Page</div>
            <div className="field-value">
              {resourceSource.contents.page !== ""
                ? resourceSource.contents.page
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Magazine":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Authors</div>
            <div className="field-value">
              {resourceSource.contents.authors !== ""
                ? resourceSource.contents.authors
                : "-"}
            </div>
            <div className="field-title">Magazine</div>
            <div className="field-value">
              {resourceSource.contents.magazine !== ""
                ? resourceSource.contents.magazine
                : "-"}
            </div>
            <div className="field-title">Page</div>
            <div className="field-value">
              {resourceSource.contents.page !== ""
                ? resourceSource.contents.page
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Brochure":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Sponsor</div>
            <div className="field-value">
              {resourceSource.contents.sponsor !== ""
                ? resourceSource.contents.sponsor
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Radio":
      case "P_TV":
        component = (
          <div className="section-content-info">
            <div className="field-title">Program Name</div>
            <div className="field-value">
              {resourceSource.contents.programName !== ""
                ? resourceSource.contents.programName
                : "-"}
            </div>
            <div className="field-title">Producer</div>
            <div className="field-value">
              {resourceSource.contents.producer !== ""
                ? resourceSource.contents.producer
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Video":
        component = (
          <div className="section-content-info">
            <div className="field-title">Name</div>
            <div className="field-value">
              {resourceSource.contents.name !== ""
                ? resourceSource.contents.name
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Content Creator</div>
            <div className="field-value">
              {resourceSource.contents.contentCreator !== ""
                ? resourceSource.contents.contentCreator
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Music":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Song</div>
            <div className="field-value">
              {resourceSource.contents.song !== ""
                ? resourceSource.contents.song
                : "-"}
            </div>
            <div className="field-title">Album</div>
            <div className="field-value">
              {resourceSource.contents.album !== ""
                ? resourceSource.contents.album
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Statue":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Merchandising":
        component = (
          <div className="section-content-info">
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Brand</div>
            <div className="field-value">
              {resourceSource.contents.brand !== ""
                ? resourceSource.contents.brand
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Clothes":
        component = (
          <div className="section-content-info">
            <div className="field-title">Type</div>
            <div className="field-value">
              {resourceSource.contents.type !== ""
                ? resourceSource.contents.type
                : "-"}
            </div>
            <div className="field-title">Brand</div>
            <div className="field-value">
              {resourceSource.contents.brand !== ""
                ? resourceSource.contents.brand
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Advertisement":
        component = (
          <div className="section-content-info">
            <div className="field-title">Sponsor</div>
            <div className="field-value">
              {resourceSource.contents.advertiser !== ""
                ? resourceSource.contents.advertiser
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Painting":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Location</div>
            <div className="field-value">
              {resourceSource.contents.location !== ""
                ? resourceSource.contents.location
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Photography":
        component = (
          <div className="section-content-info">
            <div className="field-title">Photographer</div>
            <div className="field-value">
              {resourceSource.contents.photographer !== ""
                ? resourceSource.contents.photographer
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Illustration":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Store":
        component = (
          <div className="section-content-info">
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Type</div>
            <div className="field-value">
              {resourceSource.contents.type !== ""
                ? resourceSource.contents.type
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Postcard":
        component = (
          <div className="section-content-info">
            <div className="field-title">Copywriter</div>
            <div className="field-value">
              {resourceSource.contents.copywriter !== ""
                ? resourceSource.contents.copywriter
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Stamp":
        component = (
          <div className="section-content-info">
            <div className="field-title">Country</div>
            <div className="field-value">{countryName}</div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Currency":
        component = (
          <div className="section-content-info">
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Original Value</div>
            <div className="field-value">
              {resourceSource.contents.originalValue !== ""
                ? resourceSource.contents.originalValue
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_StreetArt":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_AirCraft":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Performance":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Artist</div>
            <div className="field-value">
              {resourceSource.contents.artist !== ""
                ? resourceSource.contents.artist
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
      case "P_Poster":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Sponsor</div>
            <div className="field-value">
              {resourceSource.contents.sponsor !== ""
                ? resourceSource.contents.sponsor
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_ChirographyDisplay":
        component = (
          <div className="section-content-info">
            <div className="field-title">Sponsor</div>
            <div className="field-value">
              {resourceSource.contents.sponsor !== ""
                ? resourceSource.contents.sponsor
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_ChirographySkin":
      case "P_Other":
        component = (
          <div className="section-content-info">
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Decorative":
        component = (
          <div className="section-content-info">
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Web":
        component = (
          <div className="section-content-info">
            <div className="field-title">Owner</div>
            <div className="field-value">
              {resourceSource.contents.owner !== ""
                ? resourceSource.contents.owner
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Database":
        component = (
          <div className="section-content-info">
            <div className="field-title">Database Name</div>
            <div className="field-value">
              {resourceSource.contents.databaseName !== ""
                ? resourceSource.contents.databaseName
                : "-"}
            </div>
            <div className="field-title">Photographer</div>
            <div className="field-value">
              {resourceSource.contents.photographer !== ""
                ? resourceSource.contents.photographer
                : "-"}
            </div>
            <div className="field-title">Type</div>
            <div className="field-value">
              {resourceSource.contents.type !== ""
                ? resourceSource.contents.type
                : "-"}
            </div>
            <div className="field-title">Language</div>
            <div className="field-value">
              {resourceSource.contents.language !== ""
                ? resourceSource.contents.language
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Press":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Authors</div>
            <div className="field-value">
              {resourceSource.contents.authors !== ""
                ? resourceSource.contents.authors
                : "-"}
            </div>
            <div className="field-title">Newspaper</div>
            <div className="field-value">
              {resourceSource.contents.newspaper !== ""
                ? resourceSource.contents.newspaper
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Magazine":
        component = (
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {resourceSource.contents.title !== ""
                ? resourceSource.contents.title
                : "-"}
            </div>
            <div className="field-title">Authors</div>
            <div className="field-value">
              {resourceSource.contents.authors !== ""
                ? resourceSource.contents.authors
                : "-"}
            </div>
            <div className="field-title">Magazine</div>
            <div className="field-value">
              {resourceSource.contents.magazine !== ""
                ? resourceSource.contents.magazine
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_BlogForumPost":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Blog Name</div>
            <div className="field-value">
              {resourceSource.contents.blogName !== ""
                ? resourceSource.contents.blogName
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Radio":
      case "D_TV":
        component = (
          <div className="section-content-info">
            <div className="field-title">Program Name</div>
            <div className="field-value">
              {resourceSource.contents.programName !== ""
                ? resourceSource.contents.programName
                : "-"}
            </div>
            <div className="field-title">Producer</div>
            <div className="field-value">
              {resourceSource.contents.producer !== ""
                ? resourceSource.contents.producer
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Video":
        component = (
          <div className="section-content-info">
            <div className="field-title">Name</div>
            <div className="field-value">
              {resourceSource.contents.name !== ""
                ? resourceSource.contents.name
                : "-"}
            </div>
            <div className="field-title">Content Creator</div>
            <div className="field-value">
              {resourceSource.contents.contentCreator !== ""
                ? resourceSource.contents.contentCreator
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Platform</div>
            <div className="field-value">
              {resourceSource.contents.platform !== ""
                ? resourceSource.contents.platform
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Music":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Song</div>
            <div className="field-value">
              {resourceSource.contents.song !== ""
                ? resourceSource.contents.song
                : "-"}
            </div>
            <div className="field-title">Album</div>
            <div className="field-value">
              {resourceSource.contents.album !== ""
                ? resourceSource.contents.album
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Platform</div>
            <div className="field-value">
              {resourceSource.contents.platform !== ""
                ? resourceSource.contents.platform
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Podcast":
        component = (
          <div className="section-content-info">
            <div className="field-title">Program Name</div>
            <div className="field-value">
              {resourceSource.contents.programName !== ""
                ? resourceSource.contents.programName
                : "-"}
            </div>
            <div className="field-title">Producer</div>
            <div className="field-value">
              {resourceSource.contents.producer !== ""
                ? resourceSource.contents.producer
                : "-"}
            </div>
            <div className="field-title">Minute</div>
            <div className="field-value">
              {resourceSource.contents.minute !== ""
                ? resourceSource.contents.minute
                : "-"}
            </div>
            <div className="field-title">Platform</div>
            <div className="field-value">
              {resourceSource.contents.platform !== ""
                ? resourceSource.contents.platform
                : "-"}
            </div>
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "P_Display":
        component = (
          <div className="section-content-info">
            <div className="field-title">Sponsor</div>
            <div className="field-value">
              {resourceSource.contents.sponsor !== ""
                ? resourceSource.contents.sponsor
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Address</div>
            <div className="field-value">
              {resourceSource.contents.address !== ""
                ? resourceSource.contents.address
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Latitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLat !== ""
                ? resourceSource.contents.coordinatesLat
                : "-"}
            </div>
            <div className="field-title">Longitude</div>
            <div className="field-value">
              {resourceSource.contents.coordinatesLong !== ""
                ? resourceSource.contents.coordinatesLong
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Advertisement":
        component = (
          <div className="section-content-info">
            <div className="field-title">Sponsor</div>
            <div className="field-value">
              {resourceSource.contents.advertiser !== ""
                ? resourceSource.contents.advertiser
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Painting":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Location</div>
            <div className="field-value">
              {resourceSource.contents.location !== ""
                ? resourceSource.contents.location
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Photography":
        component = (
          <div className="section-content-info">
            <div className="field-title">Photographer</div>
            <div className="field-value">
              {resourceSource.contents.photographer !== ""
                ? resourceSource.contents.photographer
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Illustration":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Meme":
      case "D_AnimatedGIF":
      case "D_Other":
        component = (
          <div className="section-content-info">
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_SocialMediaPost":
        component = (
          <div className="section-content-info">
            <div className="field-title">Author</div>
            <div className="field-value">
              {resourceSource.contents.author !== ""
                ? resourceSource.contents.author
                : "-"}
            </div>
            <div className="field-title">Datetime</div>
            <div className="field-value">
              {resourceSource.contents.datetime !== ""
                ? resourceSource.contents.datetime
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Platform</div>
            <div className="field-value">
              {resourceSource.contents.platform !== ""
                ? resourceSource.contents.platform
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Mail":
        component = (
          <div className="section-content-info">
            <div className="field-title">From</div>
            <div className="field-value">
              {resourceSource.contents.from !== ""
                ? resourceSource.contents.from
                : "-"}
            </div>
            <div className="field-title">To</div>
            <div className="field-value">
              {resourceSource.contents.to !== ""
                ? resourceSource.contents.to
                : "-"}
            </div>
            <div className="field-title">Datetime</div>
            <div className="field-value">
              {resourceSource.contents.datetime !== ""
                ? resourceSource.contents.datetime
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Banner":
        component = (
          <div className="section-content-info">
            <div className="field-title">Publisher</div>
            <div className="field-value">
              {resourceSource.contents.publisher !== ""
                ? resourceSource.contents.publisher
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
          </div>
        );
        break;
      case "D_Doodle":
        component = (
          <div className="section-content-info">
            <div className="field-title">Date</div>
            <div className="field-value">
              {resourceSource.contents.date !== ""
                ? resourceSource.contents.date
                : "-"}
            </div>
            <div className="field-title">Description</div>
            <div className="field-value">
              {resourceSource.contents.description !== ""
                ? resourceSource.contents.description
                : "-"}
            </div>
            <div className="field-title">Year</div>
            <div className="field-value">
              {resourceSource.contents.year !== ""
                ? resourceSource.contents.year
                : "-"}
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    return component;
  }
};

export const getLicenseIcon = (type) => {
  let iconPath = "";
  switch (type) {
    case "Copyleft":
      iconPath = "images/copyleft.svg";
      break;
    case "Copyright":
      iconPath = "images/copyright.svg";
      break;
    case "CreativeCommons":
      iconPath = "images/creative-commons.svg";
      break;
    case "PublicDomain":
      iconPath = "images/public-domain.svg";
      break;
    case "Unknown":
      iconPath = "images/unknown.svg";
      break;
    default:
      break;
  }
  return iconPath;
};

export const getLicenseType = (type) => {
  let licenseType = "";
  switch (type) {
    case "Copyleft":
      licenseType = "Copyleft";
      break;
    case "Copyright":
      licenseType = "Copyright";
      break;
    case "CreativeCommons":
      licenseType = "Creative Commons";
      break;
    case "PublicDomain":
      licenseType = "Public Domain";
      break;
    case "Unknown":
      licenseType = "Unknown";
      break;
    default:
      break;
  }
  return licenseType;
};

export const showNoMedia = (type) => {
  let img = "";
  switch (type) {
    case "image":
      img = "images/no-image.png";
      break;
    case "audio":
      img = "images/no-audio.png";
      break;
    case "video":
      img = "images/no-video.png";
      break;
    default:
      break;
  }
  return img;
};
