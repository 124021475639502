import React, { useState } from "react";
import ReactQuill from "react-quill";
import "../css/RichTextEditor.css";
import "react-quill/dist/quill.snow.css";

export default function RichTextEditor(props) {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
  ];

  return (
    <div>
      <ReactQuill
        value={props.value}
        onChange={(e) => {
          props.onChange(e);
        }}
        modules={modules}
        formats={formats}
      />
    </div>
  );
}
