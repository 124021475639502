import React from "react";
import { getLicenseIcon, getLicenseType, showNoMedia } from "../js/functions";
import { Link } from "react-router-dom";

export default function ResourceCard(props) {
  return (
    <Link
      className="resource-provided-contain-info"
      to={"/resource?resourceId=" + props.resource.id}
    >
      <div className="resource-provided-info">
        {props.resource.reviewedBy !== "" && props.resource.isDraft === "0" && (
          <div className="reviewed-container">
            <div className="text-block-10 text-block-10-reviewed">REVIEWED</div>
          </div>
        )}
        {props.resource.isDraft === "1" && (
          <div className="reviewed-container">
            <div className="text-block-10 text-block-10-draft">DRAFT</div>
          </div>
        )}
        <div className="resource-provided-image-container">
          <img
            src={
              props.resource.media1 === ""
                ? showNoMedia(props.resource.type)
                : props.resource.media1
            }
            sizes="(max-width: 479px) 150px, (max-width: 767px) 20vw, (max-width: 991px) 50px, (max-width: 1919px) 5vw, 50px"
            alt="Icon"
            className="resource-provided-img"
          ></img>
        </div>
        <div className="resource-provided-info-container">
          <div className="resource-data-info-container">
            <div className="user-field-label">Referred Author</div>
            <div className="user-field-value">
              {props.resource.referredAuthor !== ""
                ? props.resource.referredAuthor.toUpperCase()
                : "-"}
            </div>
          </div>
          <div className="resource-data-info-container">
            <div className="user-field-label">Title</div>
            <div className="user-field-value">
              {props.resource.title !== ""
                ? props.resource.title.toUpperCase()
                : "-"}
              <img
                className="license-icon"
                src={getLicenseIcon(props.resource.rights)}
                title={getLicenseType(props.resource.rights)}
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="resource-provided-info-footer">
        <div className="icon-text-container-info">
          <div className="point-container"></div>
          <div className="text-block-9">
            Reference ID: <strong>{props.resource.referenceId}</strong>
          </div>
        </div>
        <div className="icon-text-container-info">
          <div className="point-container"></div>
          <div className="text-block-9">
            Version date:{" "}
            <strong>
              {props.resource.versionDateTime
                /* .split(" ")[0] */
                .replaceAll("-", "/")}
            </strong>
          </div>
        </div>
        <div className="icon-text-container-info">
          <div className="point-container"></div>
          <div className="text-block-9">
            Version: <strong>{props.resource.versionCode}</strong>
          </div>
        </div>
      </div>
      {props.resource.reviewedBy !== "" && (
        <div className="user-profile-resource-provided-info-footer">
          <div className="icon-text-container-info">
            <div className="point-container"></div>
            <div className="text-block-9">
              Reviewed by: <strong>{props.resource.reviewedBy}</strong>
            </div>
          </div>
          <div className="icon-text-container-info">
            <div className="point-container"></div>
            <div className="text-block-9">
              Revision date:{" "}
              <strong>
                {props.resource.revisionDateTime
                  /* .split(" ")[0] */
                  .replaceAll("-", "/")}
              </strong>
            </div>
          </div>
        </div>
      )}
      {props.resource.publishedBy && (
        <div className="user-profile-resource-provided-info-footer">
          <div className="icon-text-container-info">
            <div className="point-container"></div>
            <div className="text-block-9">
              Published by: <strong>{props.resource.publishedBy}</strong>
            </div>
          </div>
          <div className="icon-text-container-info">
            <div className="point-container"></div>
            <div className="text-block-9">
              Email: <strong>{props.resource.publisherEmail}</strong>
            </div>
          </div>
        </div>
      )}
    </Link>
  );
}
