import React from "react";
import "../css/MessageBox.css";

export default function MessageBox(props) {
  return (
    <div className="message-box-container">
      <div className="message-box-question-container">
        <img src="images/warning.svg"></img>
        <span>{props.question}</span>
      </div>
      {/*       <div className="message-box-buttons-container">
        <div className="button-18" role="button" onClick={props.onAction}>
          {props.actionButton.toUpperCase()}
        </div>
        <div className="button-18" role="button" onClick={props.onNoAction}>
          {props.noActionButton.toUpperCase()}
        </div>
      </div> */}
    </div>
  );
}
