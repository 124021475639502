import React, { useState, useEffect } from "react";
import ResourceCard from "../components/ResourceCard";
import Footer from "../components/Footer";
import OptionButton from "../components/OptionButton";
import NavigationBar from "../components/NavigationBar";
import Modal from "react-modal";
import { useSearchParams } from "react-router-dom";
import { LoadingInfo } from "../components/LoadingInfo";
import { post } from "../js/post";
import { getId, getToken, getUsername, isAdmin } from "../js/userData";
import "../css/UserProfile.css";
import "../css/Modal.css";
import ButtonSmall from "../components/ButtonSmall";

const currentUser = {
  username: "",
  name: "",
  lastName: "",
  location: "",
  country: "",
  email: "",
  banned: "",
  bannedBy: "",
  bannedDateTime: "",
  deleted: "",
  deletedBy: "",
  deletedDateTime: "",
  role: "",
  resourcesProvided: [],
};

export default function UserProfile() {
  const [searchParams] = useSearchParams();
  const [personalInformationPanel, setPersonalInformationPanel] =
    useState(true);
  const [resourcesProvided, setResourcesProvided] = useState(true);
  const [user, setUser] = useState(currentUser);
  const [userEdit, setUserEdit] = useState(currentUser);
  const [banUserOp, setBanUserOp] = useState(false);
  const [deleteUserOp, setDeleteUserOp] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorUserData, setErrorUserData] = useState({
    name: false,
    lastName: false,
    location: false,
    country: false,
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [toggleSave, setToggleSave] = useState(false);

  const getUser = async () => {
    setLoadingUser(true);
    const response = await post("GetUser", {
      id: searchParams.get("userId"),
      filterReviewedState: "",
      filterStrategies: "",
      filterStyle: "",
      filterMode: "",
      filterSource: "",
      filterCanonicalRegime: "",
    });
    // Filtrado de recursos no draft si soy usuario ajeno.
    if (!isAdmin() && getUsername() !== response.username) {
      let resourcesNoDraft = response.resourcesProvided;
      resourcesNoDraft = resourcesNoDraft.filter((item) => {
        return item.isDraft === "0";
      });
      response.resourcesProvided = resourcesNoDraft;
    }

    setUser(response);
    setLoadingUser(false);
    Modal.setAppElement("#root");
  };

  const getCountriesList = async () => {
    const response = await post("ListCountriesSelect", {});
    setCountriesList(response);
  };

  const banUnbanUser = async () => {
    const response = await post("SetBan", {
      token: getToken(),
      userId: searchParams.get("userId"),
      ban: user.banned === "0" ? "1" : "0",
    });
    setBanUserOp(false);
    let userAux = user;
    userAux.banned = response.isBanned;
    userAux.bannedBy = getId();
    userAux.bannedDateTime = response.bannedDatetime;
    setUser(userAux);
  };

  const deleteUser = async () => {
    await post("DeleteUser", {
      token: getToken(),
      userId: searchParams.get("userId"),
    });
  };

  const validateUserData = async () => {
    setErrorUserData({
      name: userEdit.name === "",
      lastName: userEdit.lastName === "",
      location: userEdit.location === "",
      country: userEdit.country === "",
    });

    if (
      userEdit.name === "" ||
      userEdit.lastName === "" ||
      userEdit.location === "" ||
      userEdit.country === ""
    ) {
      setErrorMessage(
        "Please ensure that all required fields are filled out correctly."
      );
    } else {
      setErrorMessage("");
      setEditUser(false);
      // Llamamos al actualizar
      await post("UpdateUser", {
        token: getToken(),
        name: userEdit.name,
        lastName: userEdit.lastName,
        location: userEdit.location,
        country: countriesList.find((e) => e.id === userEdit.country).name,
      });
      setToggleSave(true);
      await getUser();
      setTimeout(() => {
        setToggleSave(false);
      }, 2000);
      const userDataAux = JSON.parse(localStorage.getItem("userData"));
      userDataAux.name = userEdit.name + " " + userEdit.lastName;
      localStorage.setItem("userData", JSON.stringify(userDataAux));
    }
  };

  const updatePassword = async () => {
    if (currentPassword !== "" && newPassword !== "" && repeatPassword !== "") {
      if (newPassword !== repeatPassword) {
        setErrorMessage(
          "Passwords do not match. Please ensure the new password and the repeated password are identical."
        );
      } else {
        const response = await post("UpdatePasswordUser", {
          token: getToken(),
          oldPassword: currentPassword,
          newPassword: newPassword,
        });
        if (response.error !== "") {
          setErrorMessage(response.error);
        } else {
          setToggleSave(true);
          setErrorMessage("");
          setEditPassword(false);
          setTimeout(() => {
            setToggleSave(false);
          }, 2000);
        }
      }
    }
  };

  useEffect(() => {
    getCountriesList();
    getUser();
  }, []);

  return (
    <div className="user-profile">
      {loadingUser && <LoadingInfo title="Loading user profile..." />}
      {!loadingUser && (
        <div className="user-profile-contain">
          <div className="user-profile-header">
            <NavigationBar></NavigationBar>
            <div
              className="user-profile-header-title"
              onClick={() => {
                window.location = "/";
              }}
            >
              <div className="text-block-4">
                <strong className="bold-text-3">Branding</strong> the{" "}
                <strong className="bold-text-4">Canon</strong>
              </div>
            </div>
          </div>
          {!loadingUser && (
            <div className="user-profile-content">
              <div className="user-profile-title">
                <div className="title-container">
                  <div className="text-block-8">
                    User <strong>Profile</strong>
                  </div>
                </div>
              </div>
              <div className="user-profile-option-box">
                <div className="span-button span-options-title">
                  USER PROFILE OPTIONS
                </div>
                <div className="option-button-container">
                  {(isAdmin() || user.username === getUsername()) && (
                    <OptionButton
                      text={"Edit user"}
                      icon={"../images/edit.svg"}
                      onPress={() => {
                        if (!editUser) {
                          const countryId = countriesList.find(
                            (e) => e.name === user.country
                          )?.id;
                          setUserEdit({
                            ...user,
                            country: countryId,
                          });
                          setEditUser(true);
                        }
                      }}
                      disabled={editPassword}
                    />
                  )}
                  {(isAdmin() || user.username === getUsername()) && (
                    <OptionButton
                      text={"Change password"}
                      icon={"../images/padlock.svg"}
                      onPress={() => {
                        setEditPassword(true);
                      }}
                      disabled={editUser}
                    />
                  )}
                  {isAdmin() && getUsername() !== user.username && (
                    <OptionButton
                      text={user.banned === "0" ? "Ban user" : "Unban user"}
                      icon={
                        user.banned === "0"
                          ? "../images/ban.svg"
                          : "../images/quit-ban.svg"
                      }
                      onPress={() => {
                        setBanUserOp(true);
                      }}
                    />
                  )}
                  {isAdmin() && getUsername() !== user.username && (
                    <OptionButton
                      text={"Delete user"}
                      icon={"../images/remove-resource.svg"}
                      onPress={() => {
                        setDeleteUserOp(true);
                      }}
                    />
                  )}
                </div>
              </div>
              {toggleSave && (
                <div className="user-profile-box">
                  <div className="saving-information">
                    <span>
                      Changes have been{" "}
                      <span className="bold-text">successfully saved</span>.
                    </span>
                  </div>
                </div>
              )}
              {errorMessage && (
                <div className="user-profile-box">
                  <div className="error-login-container error-container">
                    <span>{errorMessage}</span>
                    <img
                      src="images/close-message.svg"
                      alt="Icon"
                      onClick={() => {
                        setErrorUserData({
                          name: false,
                          lastName: false,
                          location: false,
                          country: false,
                        });
                        setErrorMessage("");
                      }}
                    ></img>
                  </div>
                </div>
              )}
              <div className="user-profile-box">
                <div className="rcb-right-panel rcb-right-panel-extra">
                  <div className="user-profile-section">
                    <div
                      className="user-profile-section-title"
                      onClick={() => {
                        setPersonalInformationPanel(!personalInformationPanel);
                      }}
                    >
                      <img
                        src={
                          personalInformationPanel
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="15"
                        width="15"
                        alt="Open Close"
                        className="user-profile-section-icon"
                      ></img>
                      <div className="span-section-title">
                        Personal Information
                      </div>
                    </div>
                    {personalInformationPanel && (
                      <div className="user-profile-section-content">
                        {editPassword && (
                          <div className="change-password-container">
                            <div className="data-field-container">
                              <div className="data-info-container data-info-container-edit">
                                <div className="user-field-label">
                                  Current Password (*)
                                </div>
                                <div className="user-field-value">
                                  <input
                                    id="current-password"
                                    type="password"
                                    className="input-field-value w-input"
                                    maxLength="100"
                                    value={currentPassword}
                                    onChange={(e) => {
                                      setCurrentPassword(e.target.value);
                                    }}
                                  ></input>
                                </div>
                              </div>
                              <div className="data-info-container data-info-container-edit">
                                <div className="user-field-label">
                                  New Password (*)
                                </div>
                                <div className="user-field-value">
                                  <input
                                    id="new-password"
                                    type="password"
                                    className="input-field-value w-input"
                                    maxLength="100"
                                    value={newPassword}
                                    onChange={(e) => {
                                      setNewPassword(e.target.value);
                                    }}
                                  ></input>
                                </div>
                              </div>
                              <div className="data-info-container data-info-container-edit">
                                <div className="user-field-label">
                                  Repeat New Password (*)
                                </div>
                                <div className="user-field-value">
                                  <input
                                    id="repeat-new-password"
                                    type="password"
                                    className="input-field-value w-input"
                                    maxLength="100"
                                    value={repeatPassword}
                                    onChange={(e) => {
                                      setRepeatPassword(e.target.value);
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="buttons-container-row">
                              <ButtonSmall
                                text="SAVE"
                                onPress={async () => {
                                  await updatePassword();
                                }}
                              ></ButtonSmall>
                              <ButtonSmall
                                text="CANCEL"
                                onPress={() => {
                                  setEditPassword(false);
                                }}
                              ></ButtonSmall>
                            </div>
                          </div>
                        )}
                        {!editUser && (
                          <div className="user-profile-section-content-info">
                            <div className="data-field-container">
                              <div className="data-info-container">
                                <div className="user-field-label">Name</div>
                                <div className="user-field-value">
                                  {user.name}
                                </div>
                              </div>
                              <div className="data-info-container">
                                <div className="user-field-label">
                                  Last Name
                                </div>
                                <div className="user-field-value">
                                  {user.lastName}
                                </div>
                              </div>
                            </div>
                            <div className="data-field-container">
                              <div className="data-info-container">
                                <div className="user-field-label">Username</div>
                                <div className="user-field-value">
                                  {user.username}
                                </div>
                              </div>
                              {/* {(isAdmin() ||
                                getUsername() === user.username) && (
                                <div className="data-info-container">
                                  <div className="user-field-label">
                                    Password
                                  </div>
                                  <div className="user-field-value">
                                    ***************
                                  </div>
                                </div>
                              )} */}
                              <div className="data-info-container">
                                <div className="user-field-label">Email</div>
                                <div className="user-field-value">
                                  {user.email}
                                </div>
                              </div>
                            </div>
                            {(isAdmin() || getUsername() === user.username) && (
                              <div className="data-field-container">
                                <div className="data-info-container">
                                  <div className="user-field-label">
                                    Location
                                  </div>
                                  <div className="user-field-value">
                                    {user.location}
                                  </div>
                                </div>
                                <div className="data-info-container">
                                  <div className="user-field-label">
                                    Country
                                  </div>
                                  <div className="user-field-value">
                                    {user.country}
                                  </div>
                                </div>
                              </div>
                            )}
                            {(isAdmin() || getUsername() === user.username) && (
                              <div className="data-field-container">
                                <div className="data-info-container">
                                  <div className="user-field-label">Role</div>
                                  <div className="user-field-value">
                                    {user.role}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {editUser && (
                          <div className="user-profile-section-content-info">
                            <div className="data-field-container">
                              <div className="data-info-container data-info-container-edit">
                                <div className="user-field-label">Name (*)</div>
                                <div className="user-field-value">
                                  <input
                                    id="name"
                                    type="text"
                                    className={`input-field-value w-input ${
                                      errorUserData.name ? "input-error" : ""
                                    }`}
                                    maxLength="100"
                                    value={userEdit.name}
                                    onChange={(e) => {
                                      setUserEdit({
                                        ...userEdit,
                                        name: e.target.value,
                                      });
                                    }}
                                  ></input>
                                </div>
                              </div>
                              <div className="data-info-container data-info-container-edit">
                                <div className="user-field-label">
                                  Last Name (*)
                                </div>
                                <div className="user-field-value">
                                  <input
                                    id="lastname"
                                    type="text"
                                    className={`input-field-value w-input ${
                                      errorUserData.lastName
                                        ? "input-error"
                                        : ""
                                    }`}
                                    maxLength="100"
                                    value={userEdit.lastName}
                                    onChange={(e) => {
                                      setUserEdit({
                                        ...userEdit,
                                        lastName: e.target.value,
                                      });
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="data-field-container">
                              <div className="data-info-container">
                                <div className="user-field-label">Username</div>
                                <div className="user-field-value">
                                  {user.username}
                                </div>
                              </div>
                              {/* {(isAdmin() ||
                                getUsername() === user.username) && (
                                <div className="data-info-container">
                                  <div className="user-field-label">
                                    Password
                                  </div>
                                  <div className="user-field-value">
                                    ***************
                                  </div>
                                </div>
                              )} */}
                              <div className="data-info-container">
                                <div className="user-field-label">Email</div>
                                <div className="user-field-value">
                                  {user.email}
                                </div>
                              </div>
                            </div>
                            {(isAdmin() || getUsername() === user.username) && (
                              <div className="data-field-container">
                                <div className="data-info-container data-info-container-edit">
                                  <div className="user-field-label">
                                    Location (*)
                                  </div>
                                  <div className="user-field-value">
                                    <input
                                      id="name"
                                      type="text"
                                      className={`input-field-value w-input ${
                                        errorUserData.location
                                          ? "input-error"
                                          : ""
                                      }`}
                                      maxLength="100"
                                      value={userEdit.location}
                                      onChange={(e) => {
                                        setUserEdit({
                                          ...userEdit,
                                          location: e.target.value,
                                        });
                                      }}
                                    ></input>
                                  </div>
                                </div>
                                <div className="data-info-container data-info-container-edit">
                                  <div className="user-field-label">
                                    Country (*)
                                  </div>
                                  <select
                                    id="country"
                                    className={`input-field-value w-input ${
                                      errorUserData.country ? "input-error" : ""
                                    }`}
                                    value={userEdit.country}
                                    onChange={(e) => {
                                      setUserEdit({
                                        ...userEdit,
                                        country: e.target.value,
                                      });
                                    }}
                                  >
                                    {countriesList.map((option, key) => (
                                      <option key={key} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                            {(isAdmin() || getUsername() === user.username) && (
                              <div className="data-field-container">
                                <div className="data-info-container">
                                  <div className="user-field-label">Role</div>
                                  <div className="user-field-value">
                                    {user.role}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="buttons-container-row">
                              <ButtonSmall
                                text="SAVE"
                                onPress={async () => {
                                  await validateUserData();
                                }}
                              ></ButtonSmall>
                              <ButtonSmall
                                text="CANCEL"
                                onPress={() => {
                                  setUserEdit(user);
                                  setErrorMessage("");
                                  setErrorUserData({
                                    name: false,
                                    lastName: false,
                                    location: false,
                                    country: false,
                                  });
                                  setEditUser(false);
                                }}
                              ></ButtonSmall>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="user-profile-box">
                <div className="rcb-right-panel rcb-right-panel-extra">
                  <div className="user-profile-section">
                    <div
                      className="user-profile-section-title"
                      onClick={() => {
                        setResourcesProvided(!resourcesProvided);
                      }}
                    >
                      <img
                        src={
                          resourcesProvided
                            ? "images/close-section.svg"
                            : "images/open-section.svg"
                        }
                        height="15"
                        width="15"
                        alt="Open Close"
                        className="user-profile-section-icon"
                      ></img>
                      <div className="span-section-title">
                        Resources Provided{" "}
                        {user.resourcesProvided.length > 0
                          ? "(" + user.resourcesProvided.length + ")"
                          : ""}
                      </div>
                    </div>
                    {resourcesProvided && (
                      <div className="user-profile-section-content">
                        <div className="resource-provided-container">
                          {user.resourcesProvided.length > 0 &&
                            user.resourcesProvided.map((item, i) => (
                              <ResourceCard
                                resource={item}
                                key={i}
                              ></ResourceCard>
                            ))}
                          {user.resourcesProvided.length === 0 && (
                            <div className="no-resources-info">
                              <span>No resources</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Modal isOpen={banUserOp} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure you want to{" "}
                      <span className="bold-text">
                        {user.banned === "0" ? "ban" : "unban"}
                      </span>{" "}
                      this user?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        await banUnbanUser();
                      }}
                    >
                      {user.banned === "0" ? "BAN" : "UNBAN"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setBanUserOp(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={deleteUserOp} className="modal">
                <div className="message-box-container modal-box">
                  <div className="message-box-question-container">
                    <img src="images/warning.svg" alt="icon"></img>
                    <span>
                      Are you sure you want to{" "}
                      <span className="bold-text">delete</span> this user?
                    </span>
                  </div>
                  <div className="message-box-buttons-container">
                    <div
                      className="button-18"
                      role="button"
                      onClick={async () => {
                        await deleteUser();
                        window.location = "/users";
                      }}
                    >
                      {"DELETE"}
                    </div>
                    <div
                      className="button-18"
                      role="button"
                      onClick={() => {
                        setDeleteUserOp(false);
                      }}
                    >
                      {"CANCEL"}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}
          <Footer></Footer>
        </div>
      )}
    </div>
  );
}
