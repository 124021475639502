import React from "react";
import { getId } from "../js/userData";
import OptionButton from "./OptionButton";
import Switch from "react-switch";
import "../css/UserListCard.css";

export default function UserListCard(props) {
  return (
    <div
      className="resource-provided-contain-info"
      onClick={() => {
        window.open("/user?userId=" + props.user.id);
      }}
    >
      <div className="resource-provided-info">
        <div className="resource-provided-info-container op-column">
          <div className="resource-data-info-container op-row">
            <div className="user-field-label">Name:&nbsp;</div>
            <div className="user-field-value">
              {props.user.fullname !== "" ? props.user.fullname : "-"}
            </div>
          </div>
          <div className="resource-data-info-container op-row">
            <div className="user-field-label">Username:&nbsp;</div>
            <div className="user-field-value">
              {props.user.username !== "" ? props.user.username : "-"}
            </div>
          </div>
          <div className="resource-data-info-container op-row">
            <div className="user-field-label">Email:&nbsp;</div>
            <div className="user-field-value">
              {props.user.email !== "" ? props.user.email : "-"}
            </div>
          </div>
          <div className="resource-data-info-container op-row">
            <div className="user-field-label">Published Resources:&nbsp;</div>
            <div className="user-field-value">
              {props.user.nResources !== "" ? props.user.nResources : "-"}
            </div>
          </div>
        </div>
        {props.user.id !== getId() && (
          <div className="user-options-container">
            <div
              className="resource-data-info-container extra-center"
              onClick={(ev) => {
                ev.stopPropagation();
              }}
            >
              <div className="user-field-label">{props.user.role}</div>
              <Switch
                checked={props.user.role === "User"}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  props.onChangeRole();
                }}
                offColor="#7e756a"
                onColor="#bbb3b0"
                height={20}
                width={40}
                activeBoxShadow=""
                boxShadow=""
              ></Switch>
            </div>
            <div className="user-buttons-container">
              <div className="user-option-button">
                <OptionButton
                  text={props.user.isBanned === "0" ? "Ban" : "Unban"}
                  icon={
                    props.user.isBanned === "0"
                      ? "../images/ban.svg"
                      : "../images/quit-ban.svg"
                  }
                  ban={props.user.isBanned === "1"}
                  onPress={(ev) => {
                    ev.stopPropagation();
                    props.onBan();
                  }}
                />
              </div>
              <div className="user-option-button">
                <OptionButton
                  text={"Delete"}
                  icon={"../images/remove-resource.svg"}
                  onPress={(ev) => {
                    ev.stopPropagation();
                    props.onDelete();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {props.user.isBanned === "1" && (
        <div className="user-profile-resource-provided-info-footer">
          <div className="icon-text-container-info">
            <div className="point-container"></div>
            <div className="text-block-9">
              Banned by: <strong>{props.user.bannedBy}</strong>
            </div>
          </div>
          <div className="icon-text-container-info">
            <div className="point-container"></div>
            <div className="text-block-9">
              Banned datetime:{" "}
              <strong>{props.user.bannedDatetime.replaceAll("-", "/")}</strong>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
