import React, { useState } from "react";
import "../css/OptionButton.css";

export default function OptionButton(props) {
  return (
    <button
      className={`option-button ${props.ban === true ? "ban-op" : ""} ${
        props.disabled ? "button-disabled" : ""
      } `}
      onClick={props.onPress}
      disabled={props.disabled}
    >
      <div className="span-button">{props.text}</div>
      {props.icon && (
        <img src={props.icon} alt="Edit" className="image-2"></img>
      )}
    </button>
  );
}
