import React from "react";
import { Link } from "react-router-dom";
import { getLicenseIcon, getLicenseType, showNoMedia } from "../js/functions";
import "../css/SearchCard.css";

export default function SearchCard(props) {
  return (
    <div className="search-results-card">
      <div className="resource-result-content">
        <div className="resource-result-contain-info">
          <div className="resource-result-resource-image-container">
            <img
              src={
                props.resource.resourceImage === ""
                  ? showNoMedia(props.resource.type)
                  : props.resource.resourceImage
              }
              className="image-5"
              alt="Resource Img"
              loading="lazy"
            ></img>
          </div>
          <div className="section-content-info">
            <div className="field-title">Title</div>
            <div className="field-value">
              {props.resource.title.toUpperCase()}
              <img
                className="license-icon"
                src={getLicenseIcon(props.resource.rights)}
                title={getLicenseType(props.resource.rights)}
              ></img>
            </div>
            <div className="field-title">Referred Author</div>
            <div className="field-value">
              {props.resource.referredAuthor.toUpperCase()}
            </div>
            <div className="field-title">Referred Literary Work</div>
            <div className="field-value">
              {props.resource.referredLiteraryWork !== ""
                ? props.resource.referredLiteraryWork.toUpperCase()
                : "-"}
            </div>
            <div className="field-title">Published by</div>
            <div className="field-value">
              {props.resource.username.toUpperCase()}
            </div>
          </div>
        </div>
        <Link
          className="button-open-resource"
          to={"resource?resourceId=" + props.resource.resourceId}
        >
          <div className="button-small-text">ACCESS</div>
        </Link>
      </div>
      <div className="resource-result-footer">
        <div className="text-block-9">
          Reference ID:{" "}
          <strong className="bold-text-7">{props.resource.referenceId}</strong>
        </div>
        <div className="text-block-9">
          Version date:{" "}
          <strong>
            {props.resource.versionDateTime /* .split(" ")[0] */
              .replaceAll("-", "/")}
          </strong>{" "}
          - Version: <strong>{props.resource.versionCode}</strong>
        </div>
      </div>
    </div>
  );
}
