import React, { useState } from "react";
import Button from "./Button";
import { post } from "../js/post";
import "../css/Search.css";

export default function Search(props) {
  const [word, setWord] = useState("");
  const [suggestedWords, setSuggestedWords] = useState([]);

  const results = suggestedWords.map((e, i) => (
    <div
      key={i}
      className="element-found-container"
      onClick={() => {
        setWord(e.word);
        setSuggestedWords([]);
        props.onSearch(e.word);
      }}
    >
      <span>
        {e.word + " "}
        <i>({e.appearances})</i>
      </span>
    </div>
  ));

  const handleChange = (event) => {
    setWord(event.target.value);
    if (event.target.value.length >= 3) {
      getSuggestedWords(event.target.value);
    } else {
      setSuggestedWords([]);
    }
  };

  const getSuggestedWords = async (text) => {
    const response = await post("SearchSuggestions", { textInput: text });
    setSuggestedWords(response);
  };

  return (
    <div className="search-bar-full">
      <div className="search-bar-container">
        <div className="search-div form-block-2 w-form">
          <input
            type="text"
            className="text-field-3 w-input"
            maxLength="256"
            placeholder=""
            id="name-7"
            value={word}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.onSearch(word);
              }
            }}
          ></input>
          {word.length > 0 && (
            <div
              className="reset-button"
              onClick={() => {
                setWord("");
              }}
            >
              <img
                src="../images/cerrar.svg"
                alt="close"
                onClick={() => {
                  props.onClose();
                }}
              ></img>
            </div>
          )}
        </div>
        <div className="button button-search">
          <img
            src="images/search.svg"
            loading="lazy"
            alt="Icon"
            className="image-13"
          ></img>
          <Button
            text="SEARCH"
            onPressButton={() => {
              props.onSearch(word);
            }}
          ></Button>
        </div>
      </div>
      {word.length > 0 && (
        <div className="search-div-result-container">
          <div
            className={`search-div-result ${
              suggestedWords.length < 5 ? "hide-scrollbar" : ""
            }`}
          >
            {results}
          </div>
        </div>
      )}
    </div>
  );
}
