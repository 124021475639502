export const getToken = () => {
  let token = "";
  if (localStorage.getItem("userData") !== null) {
    token = JSON.parse(localStorage.getItem("userData")).token;
  }
  return token;
};

export const getId = () => {
  let id = "";
  if (localStorage.getItem("userData") !== null) {
    id = JSON.parse(localStorage.getItem("userData")).id;
  }
  return id;
};

export const isAdmin = () => {
  let isAdmin = false;
  if (localStorage.getItem("userData") !== null) {
    isAdmin = JSON.parse(localStorage.getItem("userData")).role === "Admin";
  }
  return isAdmin;
};

export const getUsername = () => {
  let username = "";
  if (localStorage.getItem("userData") !== null) {
    username = JSON.parse(localStorage.getItem("userData")).username;
  }
  return username;
};

export const getName = () => {
  let fullname = "";
  if (localStorage.getItem("userData") !== null) {
    fullname = JSON.parse(localStorage.getItem("userData")).name;
  }
  return fullname;
};
