import React from "react";
import { Link } from "react-router-dom";
import { getLicenseIcon, getLicenseType, showNoMedia } from "../js/functions";
import "../css/Card.css";

export default function Card(props) {
  const url = props.url ? props.url : "resource?resourceId=";

  return (
    <div className="card">
      <div className="card-img">
        <img
          src={
            props.resource.resourceImage === ""
              ? showNoMedia(props.resource.type)
              : props.resource.resourceImage
          }
        ></img>
      </div>
      <div className="card-info">
        <span className="card-info-title">
          {props.resource.title}
          <img
            className="license-icon"
            src={getLicenseIcon(props.resource.rights)}
            title={getLicenseType(props.resource.rights)}
          ></img>
        </span>
        <span className="card-info-author">
          {props.resource.referredAuthor}
        </span>
        <span className="card-info-user">{props.resource.username}</span>
        <Link
          className="button-open-resource button-open-resource-extra"
          to={url + props.resource.resourceId}
          target={props.url ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          <div className="button-small-text">ACCESS</div>
        </Link>
      </div>
    </div>
  );
}
