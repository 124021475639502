import React, { useState } from "react";

export default function ButtonSmall(props) {
  return (
    <div
      className="button-open-resource button-open-resource-extra"
      onClick={() => {
        props.onPress();
      }}
    >
      <span className="button-small-text">{props.text}</span>
    </div>
  );
}
