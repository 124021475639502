import React, { useState, useEffect } from "react";
import "../css/Main.css";
import { post } from "../js/post";
import { getToken, isAdmin } from "../js/userData";
import { useSearchParams } from "react-router-dom";
import Button from "../components/Button";
import Search from "../components/Search";
import SocialButton from "../components/SocialButton";
import Card from "../components/Card";
import NavigationBar from "../components/NavigationBar";
import SearchCard from "../components/SearchCard";
import { LoadingInfo } from "../components/LoadingInfo";
import ImageGallery from "react-image-gallery";
import Map from "../components/Map";
import { dateRangeOptionsList, tagsToShowOptionsList } from "../js/utils";

export default function Main() {
  const currentYear = new Date().getFullYear();
  const [referredAuthorsList, setReferredAuthorsList] = useState([
    { id: "", name: "Referred Author (All)" },
  ]);
  const [sourcesList, setSourcesList] = useState([
    { categoryId: "", name: "Sources (All)" },
  ]);
  const [strategiesList, setStrategiesList] = useState([
    { id: "", name: "Strategies (All)" },
  ]);
  const [stylesList, setStylesList] = useState([
    { id: "", name: "Styles (All)" },
  ]);
  const [modesList, setModesList] = useState([{ id: "", name: "Modes (All)" }]);
  const [canonicalRegimesList, setCanonicalRegimesList] = useState([
    { id: "", name: "Canonical Regimes (All)" },
  ]);
  const [sectionContent, setSectionContent] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [filterReferredAuthorValue, setFilterReferredAuthorValue] =
    useState("");
  const [filterStrategiesValue, setFilterStrategiesValue] = useState("");
  const [filterStyleValue, setFilterStyleValue] = useState("");
  const [filterModeValue, setFilterModeValue] = useState("");
  const [filterCanonicalRegimeValue, setFilterCanonicalRegimeValue] =
    useState("");
  const [filterSourceValue, setFilterSourceValue] = useState("");
  const [filterDateRangeValue, setFilterDateRangeValue] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [filterTagsToShow, setFilterTagsToShow] = useState("1");
  const [searchInput, setSearchInput] = useState("");
  const [toggleSearchOp, setToggleSearchOp] = useState(false);
  const [loadReferredAuthors, setLoadReferredAuthors] = useState(true);
  const [tagSelected, setTagSelected] = useState("");
  // Search type: 0 -> general (input), 1 -> tag, 2 -> referred author, 3 -> literary work
  const [searchingBy, setSearchingBy] = useState(0);
  // External search from resource
  const [externalSearch, setExternalSearch] = useSearchParams();

  const [viewMode, setViewMode] = useState(0);
  const [resourceImageGallery, setResourceImageGallery] = useState([]);
  const [activeGalleryResource, setActiveGalleryResource] = useState({});
  const [topTagsSection, setTopTagsSection] = useState([]);

  // Image Gallery
  const [toggleFullScreenGallery, setToggleFullScreenGallery] = useState(false);

  // Resources Map
  const [resourcesMap, setResourcesMap] = useState([]);

  const getLoadHome = async () => {
    const response = await post("LoadHome", {});
    setSectionContent(response);
  };

  const getTopTags = async (num) => {
    const response = await post("LoadTopTags", {
      numTags: num,
    });
    setTopTagsSection(response);
  };

  const getResourcesMap = async () => {
    const response = await post("GetResourcesMap", {});
    setResourcesMap(response);
  };

  const search = async (word) => {
    if (word.length > 0) {
      setSearchInput(word);
      const response = await post("Search", {
        searchInput: word,
        filterReferredAuthor: filterReferredAuthorValue,
        filterStrategies: filterStrategiesValue,
        filterStyle: filterStyleValue,
        filterMode: filterModeValue,
        filterSource: filterSourceValue,
        filterCanonicalRegime: filterCanonicalRegimeValue,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      });
      setSearchResult(response);
      if (loadReferredAuthors) {
        getReferredAuthorsListSelect(response);
      }
    }
  };

  const searchGalleryMode = async (word) => {
    if (word.length > 0) {
      setSearchInput(word);
      const response = await post("SearchGalleryMode", {
        searchInput: word,
        filterReferredAuthor: filterReferredAuthorValue,
        filterStrategies: filterStrategiesValue,
        filterStyle: filterStyleValue,
        filterMode: filterModeValue,
        filterSource: filterSourceValue,
        filterCanonicalRegime: filterCanonicalRegimeValue,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      });
      setActiveGalleryResource(response[0]);
      createImageGallery(response);
    }
  };

  const createImageGallery = (resources) => {
    const imgList = [];
    resources.forEach((e) => {
      imgList.push({
        original: e.media,
        thumbnail: e.media,
        referenceId: e.referenceId,
        resourceId: e.id,
      });
    });
    setResourceImageGallery(imgList);
  };

  const getSourcesListSelect = async () => {
    const response = await post("ListSourcesSelect", {
      type: "",
    });
    response.unshift({
      categoryId: "",
      name: "Sources (All)",
    });
    setSourcesList(response);
  };

  const getStrategiesListSelect = async () => {
    const response = await post("ListStrategiesSelect", {});
    response.unshift({
      id: "",
      name: "Strategies (All)",
    });
    setStrategiesList(response);
  };

  const getStylesListSelect = async () => {
    const response = await post("ListStylesSelect", {});
    response.unshift({
      id: "",
      name: "Styles (All)",
    });
    setStylesList(response);
  };

  const getModesListSelect = async () => {
    const response = await post("ListModesSelect", {});
    response.unshift({
      id: "",
      name: "Modes (All)",
    });
    setModesList(response);
  };

  const getCanonicalRegimesListSelect = async () => {
    const response = await post("ListCanonicalRegimesSelect", {});
    response.unshift({
      id: "",
      name: "Canonical Regimes (All)",
    });
    setCanonicalRegimesList(response);
  };

  const searchByTag = async (idTag) => {
    const response = await post("SearchByTag", {
      tagId: idTag,
      filterReferredAuthor: filterReferredAuthorValue,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    setSearchResult(response);
    getReferredAuthorsListSelect(response);
  };

  const searchByTagGallery = async (idTag) => {
    const response = await post("SearchByTagGalleryMode", {
      tagId: idTag,
      filterReferredAuthor: filterReferredAuthorValue,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    createImageGallery(response);
    setActiveGalleryResource(response[0]);
  };

  const searchByReferredAuthor = async (referredAuthor) => {
    const response = await post("SearchByReferredAuthor", {
      referredAuthor,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    setSearchResult(response);
    handleClickScroll();
    getReferredAuthorsListSelect(response);
  };

  const searchByReferredAuthorGallery = async (referredAuthor) => {
    const response = await post("SearchByReferredAuthorGalleryMode", {
      referredAuthor,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    createImageGallery(response);
    setActiveGalleryResource(response[0]);
    handleClickScroll();
  };

  const searchByLiteraryWork = async (literaryWork) => {
    const response = await post("SearchByLiteraryWork", {
      literaryWork,
      filterReferredAuthor: filterReferredAuthorValue,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    setSearchResult(response);
    getReferredAuthorsListSelect(response);
    handleClickScroll();
  };

  const searchByLiteraryWorkGallery = async (literaryWork) => {
    const response = await post("SearchByLiteraryWorkGalleryMode", {
      literaryWork,
      filterReferredAuthor: filterReferredAuthorValue,
      filterStrategies: filterStrategiesValue,
      filterStyle: filterStyleValue,
      filterMode: filterModeValue,
      filterSource: filterSourceValue,
      filterCanonicalRegime: filterCanonicalRegimeValue,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    createImageGallery(response);
    setActiveGalleryResource(response[0]);
    handleClickScroll();
  };

  const getReferredAuthorsListSelect = (authors) => {
    let list = [];
    authors.map((item) => {
      return list.push({ id: item.referredAuthor, name: item.referredAuthor });
    });
    list.unshift({ id: "", name: "Referred Author (All)" });
    list = list.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    setReferredAuthorsList(list);
  };

  const resetFilters = () => {
    setFilterModeValue("");
    setFilterCanonicalRegimeValue("");
    setFilterReferredAuthorValue("");
    setFilterSourceValue("");
    setFilterStrategiesValue("");
    setFilterStyleValue("");
    setFilterDateRangeValue("");
    setSelectedStartDate("");
    setSelectedEndDate("");
  };

  const handleClickScroll = () => {
    const element = document.getElementById("main");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  const GetTagName = async () => {
    const tagName = await post("GetTagName", {
      tagId: tagSelected,
    });
    setSearchInput(tagName.tag);
  };

  const setStartDateEndDate = (filter) => {
    const currentDate = new Date();
    const today = currentDate.toISOString().split("T")[0];

    const getDateOffset = (offset) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - offset);
      return date.toISOString().split("T")[0];
    };

    let startDate = today;
    let endDate = today;

    switch (filter) {
      case "":
        startDate = "";
        endDate = "";
        break;
      case "1":
        startDate = getDateOffset(1); // Yesterday
        endDate = startDate;
        break;
      case "2":
        startDate = getDateOffset(7); // Last 7 days
        break;
      case "3":
        startDate = getDateOffset(30); // Last 30 days
        break;
      case "4":
        startDate = getDateOffset(90); // Last 90 days
        break;
      case "5":
        startDate = getDateOffset(365); // Last 365 days
        break;
      default:
        break;
    }

    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  useEffect(() => {
    getLoadHome();
    getResourcesMap();
    getTopTags("10");
    getSourcesListSelect();
    getStrategiesListSelect();
    getStylesListSelect();
    getModesListSelect();
    getCanonicalRegimesListSelect();
    if (externalSearch.get("referredAuthor")) {
      setSearchInput(externalSearch.get("referredAuthor"));
      setSearchingBy(2);
    } else if (externalSearch.get("literaryWork")) {
      setSearchInput(externalSearch.get("literaryWork"));
      setSearchingBy(3);
    } else if (externalSearch.get("tag")) {
      GetTagName();
      setTagSelected(externalSearch.get("tag"));
      searchByTag(externalSearch.get("tag"));
      searchByTagGallery(externalSearch.get("tag"));
      setSearchingBy(1);
      setToggleSearchOp(true);
    }
    switch (searchingBy) {
      case 0:
        search(searchInput);
        searchGalleryMode(searchInput);
        break;
      case 1:
        searchByTag(tagSelected);
        searchByTagGallery(tagSelected);
        break;
      case 2:
        searchByReferredAuthor(searchInput);
        searchByReferredAuthorGallery(searchInput);
        setToggleSearchOp(true);
        break;
      case 3:
        searchByLiteraryWork(searchInput);
        searchByLiteraryWorkGallery(searchInput);
        setToggleSearchOp(true);
        break;
      default:
        break;
    }
  }, [
    toggleSearchOp,
    filterReferredAuthorValue,
    filterSourceValue,
    filterStrategiesValue,
    filterStyleValue,
    filterModeValue,
    filterCanonicalRegimeValue,
    loadReferredAuthors,
    searchInput,
    selectedStartDate,
    selectedEndDate,
  ]);

  return (
    <div className="main">
      <div className="main-header">
        {getToken() && <NavigationBar></NavigationBar>}
        {!getToken() && (
          <div className="main-header-icons" style={{ gap: "2px" }}>
            <img src="images/logo1.png"></img>
            <img src="images/logo2.png"></img>
            <img src="images/logo3.png"></img>
          </div>
        )}
        <div className="main-header-title">
          <div
            className="main-footer-info-bar"
            onClick={() => {
              window.location = "/";
            }}
          >
            <span>
              <b>Branding</b> the <b>Canon</b>
            </span>
          </div>
          {localStorage.getItem("userData") === null && (
            <div className="button-container option-button-container">
              <Button
                text="LOGIN"
                onPressButton={() => {
                  window.location = "/login";
                }}
              ></Button>
              <Button
                text="REGISTER"
                onPressButton={() => {
                  window.location = "/register";
                }}
              ></Button>
            </div>
          )}
        </div>
        <div className="main-text-section project-description">
          <span>
            This database is an integral part of the research project
            'Literature&Co: Canon, Mediation, and Branding in the Postliterary
            Iberian Systems (XX-XXI Centuries)' [PID2021-127608NB-I00], funded
            by MICIU/AEI/10.13039/501100011033/FEDER, EU. Additionally, since
            October 2025, thanks to the collaboration of the Spanish Foundation
            for Science and Technology (FECYT) through the project FCT-23-18998,
            the database is a citizen science platform, open to public
            collaboration from those interested in contributing additional
            post-literary manifestations of canonical authors. For a deeper
            understanding of our research project and the diverse range of
            materials available in our archive, we invite you to explore more
            information on our{" "}
            <a
              href="https://brandingthecanon.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              website.
            </a>
            <br /> <br />
            Join us on this enriching journey as we delve into the dynamic world
            of post-literary expression, mediation, and branding in the Iberian
            literary landscape.
          </span>
        </div>
        <div className="search-container">
          <Search
            onSearch={(word) => {
              setViewMode(0);
              setExternalSearch({});
              if (word.length > 0) {
                setSearchingBy(0);
                setTagSelected("");
                setToggleSearchOp(true);
                resetFilters();
                setSearchInput(word);
                setLoadReferredAuthors(true);
              }
            }}
            onClose={() => {
              setViewMode(0);
              resetFilters();
              setToggleSearchOp(false);
              setSearchInput("");
              setLoadReferredAuthors(false);
            }}
          ></Search>
        </div>
      </div>
      <div id="main"></div>
      {toggleSearchOp && sectionContent.length > 0 && (
        <div className="main-content">
          <div className="section-title title-pad">
            <div className="text-block-8">
              Search <strong>Results</strong>
            </div>
          </div>
          <div className="search-result-filter-container">
            <div className="view-mode-container">
              <span
                className={`${viewMode === 0 ? "selected-mode" : ""}`}
                onClick={() => {
                  setViewMode(0);
                }}
              >
                Resource Card View
              </span>
              <div>|</div>
              <span
                className={`${viewMode === 1 ? "selected-mode" : ""}`}
                onClick={() => {
                  setViewMode(1);
                }}
              >
                Gallery View
              </span>
            </div>
            <div className="main-panel-filter-bar">
              <div className="span-button span-options-title">FILTERS</div>
              <div className="filter-options-container">
                <div className="filter-op-container">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter01"
                      className="select-field-3 w-select"
                      value={filterReferredAuthorValue}
                      onChange={(e) => {
                        setFilterReferredAuthorValue(e.target.value);
                        setLoadReferredAuthors(false);
                      }}
                    >
                      {referredAuthorsList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="filter-op-container">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter02"
                      className="select-field-3 w-select"
                      value={filterSourceValue}
                      onChange={(e) => {
                        setFilterSourceValue(e.target.value);
                        setLoadReferredAuthors(false);
                      }}
                    >
                      {sourcesList.map((option, key) => (
                        <option key={key} value={option.categoryId}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="filter-op-container">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter03"
                      className="select-field-3 w-select"
                      value={filterStrategiesValue}
                      onChange={(e) => {
                        setFilterStrategiesValue(e.target.value);
                        setLoadReferredAuthors(false);
                      }}
                    >
                      {strategiesList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="filter-op-container">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter04"
                      className="select-field-3 w-select"
                      value={filterStyleValue}
                      onChange={(e) => {
                        setFilterStyleValue(e.target.value);
                        setLoadReferredAuthors(false);
                      }}
                    >
                      {stylesList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="filter-op-container">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter05"
                      className="select-field-3 w-select"
                      value={filterModeValue}
                      onChange={(e) => {
                        setFilterModeValue(e.target.value);
                        setLoadReferredAuthors(false);
                      }}
                    >
                      {modesList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="filter-op-container filter-op-container-extra">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter06"
                      className="select-field-3 w-select"
                      value={filterCanonicalRegimeValue}
                      onChange={(e) => {
                        setFilterCanonicalRegimeValue(e.target.value);
                        setLoadReferredAuthors(false);
                      }}
                    >
                      {canonicalRegimesList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="filter-op-container filter-op-container-extra">
                  <div className="form-block-10 w-form">
                    <select
                      id="filter07"
                      className="select-field-3 w-select"
                      value={filterDateRangeValue}
                      onChange={(e) => {
                        setFilterDateRangeValue(e.target.value);
                        setLoadReferredAuthors(false);
                        setStartDateEndDate(e.target.value);
                      }}
                    >
                      {dateRangeOptionsList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {isAdmin() && (
                  <div className="filter-date-range-container">
                    <div className="date-range-container">
                      Start Date
                      <input
                        id="startDate"
                        type="date"
                        className="input-field-value w-input input-uc input-date-range"
                        value={selectedStartDate}
                        onChange={(e) => {
                          setSelectedStartDate(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="date-range-container">
                      End Date
                      <input
                        id="endDate"
                        type="date"
                        className="input-field-value w-input input-uc input-date-range"
                        value={selectedEndDate}
                        onChange={(e) => {
                          setSelectedEndDate(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="reset-filter-button"
                title="Reset filters"
                onClick={() => {
                  resetFilters();
                }}
              >
                <img src="images/reset.svg" alt="icon"></img>
              </div>
            </div>
            <div className="main-panel-filter-bar search-results-message">
              <span>
                <img src="/images/info.svg" alt="icon"></img>
                <span className="bold-text">
                  {viewMode === 0
                    ? searchResult.length
                    : resourceImageGallery.length}
                </span>{" "}
                {viewMode === 0 ? "resources" : "images"} found with{" "}
                <span className="bold-text">{searchInput}.</span>
              </span>
            </div>
          </div>
          {viewMode === 1 && resourceImageGallery.length > 0 && (
            <div className="selected-gallery-resource">
              {activeGalleryResource.referenceId}
              <img
                src="images/access-search.svg"
                title="Visit resource"
                onClick={() => {
                  window.open(
                    "/resource?resourceId=" + activeGalleryResource.resourceId
                  );
                }}
                alt="icon"
              ></img>
            </div>
          )}
          <div className="search-results-container">
            {viewMode === 0 && (
              <div className="search-results-container-data">
                {searchResult.map((item, i) => {
                  return <SearchCard resource={item} key={i}></SearchCard>;
                })}
              </div>
            )}
            {viewMode === 1 && resourceImageGallery.length > 0 && (
              <div className="resource-image-gallery-container">
                <ImageGallery
                  items={resourceImageGallery}
                  showPlayButton={false}
                  showThumbnails={true}
                  showBullets={false}
                  showFullscreenButton={true}
                  onThumbnailClick={(event, index) => {
                    setActiveGalleryResource(resourceImageGallery[index]);
                  }}
                  onSlide={(currentIndex) => {
                    setActiveGalleryResource(
                      resourceImageGallery[currentIndex]
                    );
                  }}
                  onScreenChange={() => {
                    setToggleFullScreenGallery(!toggleFullScreenGallery);
                  }}
                  additionalClass={
                    toggleFullScreenGallery ? "image-gallery-fullscreen" : ""
                  }
                ></ImageGallery>
              </div>
            )}
          </div>
        </div>
      )}
      {sectionContent.length === 0 && (
        <div className="loading-info-home">
          <LoadingInfo title="Loading resources..." />
        </div>
      )}
      {sectionContent.length > 0 &&
        sectionContent.map((item, i) => {
          return (
            <section
              key={i}
              className={i % 2 === 0 ? "section-left" : "section-right"}
            >
              <div className="section-title">
                <span>{item.sectionTitle}</span>
              </div>
              <div className="section-contain">
                {item.sectionContent.map((element, j) => {
                  return <Card resource={element} key={j}></Card>;
                })}
              </div>
            </section>
          );
        })}
      {/* MAPA */}
      <section
        className={
          topTagsSection.concat(sectionContent).length % 2 === 0
            ? "section-left"
            : "section-right"
        }
      >
        <div className="section-title">
          <span>Map</span>
        </div>
        <div className="section-contain section-contain-map">
          <Map resources={resourcesMap}></Map>
        </div>
      </section>
      {/* TOP TAGS */}
      {topTagsSection.length > 0 && (
        <section className={"section-right"}>
          <div className="section-title">
            <span>{topTagsSection[0].sectionTitle}</span>
          </div>
          <div className="filter-section-container">
            <span>Tags to show</span>
            <div className="filter-op-container filter-op-container-extra">
              <div className="form-block-10 w-form">
                <select
                  id="filter07"
                  className="select-field-3 w-select"
                  value={filterTagsToShow}
                  onChange={async (e) => {
                    const num = tagsToShowOptionsList.find(
                      (t) => t.id === e.target.value
                    ).name;
                    setFilterTagsToShow(e.target.value);
                    await getTopTags(num);
                  }}
                >
                  {tagsToShowOptionsList.map((option, key) => (
                    <option key={key} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="section-contain section-top-tags-contain">
            {topTagsSection[0].sectionContent.map((e, i) => {
              return (
                <div
                  className="tag-container"
                  key={i}
                  onClick={() => {
                    setExternalSearch({});
                    setSearchingBy(1);
                    setSearchInput(e.tag);
                    setToggleSearchOp(true);
                    handleClickScroll();
                    setTagSelected(e.idTag);
                    searchByTag(e.idTag);
                    searchByTagGallery(e.idTag);
                  }}
                >
                  <span>{e.tag}</span>
                </div>
              );
            })}
          </div>
        </section>
      )}
      <div className="main-footer">
        <div className="main-footer-info">
          <span className="span-title">
            <b>Branding</b> the <b>Canon</b>
          </span>
          <div className="main-contact-info">
            <span className="span-contact">CONTACT</span>
            <span className="span-email">info@brandingthecanon.com</span>
          </div>
          <div className="main-footer-icons">
            <SocialButton
              img={"../images/x.svg"}
              url={"https://twitter.com/post_literature"}
            ></SocialButton>
            <SocialButton
              img={"../images/instagram.svg"}
              url={"https://www.instagram.com/post_literature/"}
            ></SocialButton>
          </div>
        </div>
        <div className="main-footer-developer">
          <a
            href="https://himalayac.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer-logo"
              src="images/himalaya.png"
              alt="himalaya"
            ></img>
          </a>
          <a
            href="https://himalayac.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Developer by Himalaya Computing, {currentYear}
          </a>
        </div>
      </div>
    </div>
  );
}
