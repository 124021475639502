import React, { useState } from "react";
import "../css/TagCard.css";

export default function TagCard(props) {
  return (
    <div className="tag-card-container">
      <div className="resource-data-info-container op-row">
        <div className="user-field-label">Name:&nbsp;</div>
        <div className="user-field-value">{props.tag.name}</div>
      </div>
      <div className="resource-data-info-container op-row">
        <div className="user-field-label">Apperances:&nbsp;</div>
        <div className="user-field-value">{props.tag.resources.length}</div>
      </div>
      <div className="resource-data-info-container op-row op-extra">
        <div className="user-field-label">Resources:</div>
        <div className="tags-container-list op-extra-scroll">
          {props.tag.resources.length > 0 &&
            props.tag.resources.map((item, i) => {
              return (
                <span
                  key={i}
                  className="suggestion-span"
                  onClick={() => {
                    window.open("resource?resourceId=" + item.id, "_blank");
                  }}
                >
                  {"• " + item.title + " - " + item.referenceId}
                </span>
              );
            })}
        </div>
        <div></div>
      </div>
    </div>
  );
}
