import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import NavigationBar from "../components/NavigationBar";
import UserListCard from "../components/UserListCard";
import Modal from "react-modal";
import { LoadingInfo } from "../components/LoadingInfo";
import { post } from "../js/post";
import { userSortingOptions } from "../js/selectData";
import { getId, getToken } from "../js/userData";
import "../css/Users.css";

export default function Users() {
  const [userList, setUserList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [userSortingFilterValue, setUserSortingFilterValue] = useState("0");
  const [loadingUserList, setLoadingUserList] = useState(false);
  const [banUserOp, setBanUserOp] = useState(false);
  const [deleteUserOp, setDeleteUserOp] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [userToBan, setUserToBan] = useState({});

  Modal.setAppElement("#root");

  const getUserList = async () => {
    setLoadingUserList(true);
    const response = await post("GetUsers", {
      token: getToken(),
      searchInput: searchInput,
    });
    setLoadingUserList(false);
    setUserList(response);
    setSearchInput("");
  };

  const sortUserList = (event) => {
    let userListAux = userList;
    switch (event) {
      case "0":
        userListAux.sort((a, b) => {
          if (a.role < b.role) {
            return -1;
          }
          if (a.role > b.role) {
            return 1;
          }
          return 0;
        });
        setUserList(userListAux);
        break;
      case "1":
        userListAux.sort((a, b) => {
          if (a.fullname < b.fullname) {
            return -1;
          }
          if (a.fullname > b.fullname) {
            return 1;
          }
          return 0;
        });
        setUserList(userListAux);
        break;
      case "2":
        userListAux.sort((a, b) => {
          if (a.nResources < b.nResources) {
            return 1;
          }
          if (a.nResources > b.nResources) {
            return -1;
          }
          return 0;
        });
        setUserList(userListAux);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const deleteUser = async () => {
    await post("DeleteUser", {
      token: getToken(),
      userId: userToDelete.id,
    });
    let userListAux = userList;
    userListAux = userListAux.filter((u) => u.id !== userToDelete.id);
    setUserList(userListAux);
    setDeleteUserOp(false);
    setUserToDelete({});
  };

  const banUnbanUser = async () => {
    const response = await post("SetBan", {
      token: getToken(),
      userId: userToBan.id,
      ban: userToBan.isBanned === "0" ? "1" : "0",
    });
    let userListAux = userList;
    userListAux = userListAux.map((item) => {
      if (item.id === userToBan.id) {
        item.isBanned = response.isBanned;
        item.bannedBy = response.bannedBy;
        item.bannedDatetime = response.bannedDatetime;
      }
      return item;
    });
    setUserList(userListAux);
    setBanUserOp(false);
    setUserToBan({});
  };

  const changeUserRole = async (user) => {
    await post("SetRole", {
      token: getToken(),
      userId: user.id,
      role: user.role === "Admin" ? "User" : "Admin",
    });
    let userListAux = userList;
    userListAux = userListAux.map((item) => {
      if (item.id === user.id) {
        item.role = user.role === "Admin" ? "User" : "Admin";
      }
      return item;
    });
    setUserList(userListAux);
  };

  const resetFilters = () => {
    setUserSortingFilterValue("0");
    setSearchInput("");
    getUserList();
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div className="admin-panel">
      <div className="admin-content-section">
        <div className="admin-panel-header-container">
          <NavigationBar></NavigationBar>
          <div
            className="admin-panel-header-title"
            onClick={() => {
              window.location = "/";
            }}
          >
            <div className="text-block-4">
              <strong className="bold-text-3">Branding</strong> the{" "}
              <strong className="bold-text-4">Canon</strong>
            </div>
          </div>
        </div>
        <div className="admin-panel-content">
          <div className="admin-panel-title">
            <div className="title-container">
              <div className="text-block-8">
                User <strong>List</strong>
              </div>
            </div>
          </div>
          <div className="admin-panel-filter-bar users-search-bar">
            <div className="span-button span-options-title">SEARCH</div>
            <div className="filter-options-container users-options-container">
              <div className="user-search-container">
                <input
                  className="user-search-input"
                  value={searchInput}
                  onChange={handleChange}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      await getUserList();
                    }
                  }}
                ></input>
                <div
                  className="user-search-button"
                  onClick={async () => {
                    await getUserList();
                  }}
                >
                  <img src="../images/search.svg"></img>
                </div>
              </div>
              <div className="filter-op-container">
                <div className="form-block-10 w-form">
                  <select
                    id="field-4"
                    className="select-field-3 w-select"
                    value={userSortingFilterValue}
                    onChange={(e) => {
                      setUserSortingFilterValue(e.target.value);
                      sortUserList(e.target.value);
                    }}
                  >
                    {userSortingOptions.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                className="reset-filter-button"
                title="Reset filters"
                onClick={() => {
                  resetFilters();
                }}
              >
                <img src="images/reset.svg"></img>
              </div>
            </div>
          </div>
          <div className="user-profile-box admin-panel-box">
            <div className="rcb-right-panel rcb-right-panel-extra">
              <div className="admin-panel-section">
                <div className="admin-panel-section-title admin-panel-section-title-extra">
                  <div className="span-section-title">
                    User List{" "}
                    {userList.length > 0 ? "(" + userList.length + ")" : ""}
                  </div>
                </div>
                <div className="admin-panel-section-content">
                  <div className="admin-panel-last-resources-provided">
                    {loadingUserList && (
                      <LoadingInfo title="Loading users..."></LoadingInfo>
                    )}
                    {!loadingUserList && (
                      <div className="admin-panel-last-resources-provided-container">
                        {userList.length > 0 &&
                          userList.map((item, i) => (
                            <UserListCard
                              key={i}
                              user={item}
                              onDelete={() => {
                                setUserToDelete(item);
                                setDeleteUserOp(true);
                              }}
                              onBan={() => {
                                setUserToBan(item);
                                setBanUserOp(true);
                              }}
                              onChangeRole={async () => {
                                await changeUserRole(item);
                              }}
                            ></UserListCard>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={deleteUserOp} className="modal">
            <div className="message-box-container modal-box">
              <div className="message-box-question-container">
                <img src="images/warning.svg" alt="icon"></img>
                <span>
                  Are you sure to <span className="bold-text">delete</span> the
                  user{" "}
                  <span className="bold-text">{userToDelete.fullname}</span>?
                </span>
              </div>
              <div className="message-box-buttons-container">
                <div
                  className="button-18"
                  role="button"
                  onClick={async () => {
                    await deleteUser();
                  }}
                >
                  {"DELETE"}
                </div>
                <div
                  className="button-18"
                  role="button"
                  onClick={() => {
                    setDeleteUserOp(false);
                  }}
                >
                  {"CANCEL"}
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={banUserOp} className="modal">
            <div className="message-box-container modal-box">
              <div className="message-box-question-container">
                <img src="images/warning.svg" alt="icon"></img>
                <span>
                  Are you sure to{" "}
                  <span className="bold-text">
                    {userToBan.isBanned === "0" ? "ban" : "unban"}
                  </span>{" "}
                  the user{" "}
                  <span className="bold-text">{userToBan.fullname}</span>?
                </span>
              </div>
              <div className="message-box-buttons-container">
                <div
                  className="button-18"
                  role="button"
                  onClick={async () => {
                    await banUnbanUser();
                  }}
                >
                  {userToBan.isBanned === "0" ? "BAN" : "UNBAN"}
                </div>
                <div
                  className="button-18"
                  role="button"
                  onClick={() => {
                    setBanUserOp(false);
                  }}
                >
                  {"CANCEL"}
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
