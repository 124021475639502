import React, { useState } from "react";
import "../css/SocialButton.css";

export default function SocialButton(props) {
  const [img] = useState(props.img);
  const [url] = useState(props.url);
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div className="social-button">
        <img src={img} alt="icon"></img>
      </div>
    </a>
  );
}
