import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "../css/Map.css";
import "../css/Card.css";
import { getLicenseIcon, getLicenseType } from "../js/functions";

const Map = ({ resources }) => {
  const [activeResource, setActiveResource] = useState(null);

  const customIcon = new L.Icon({
    iconUrl: "../images/resource-marker.svg",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const handleMarkerClick = (resource) => {
    if (activeResource && activeResource.resourceId === resource.resourceId) {
      setActiveResource(null);
    } else {
      setActiveResource(resource);
    }
  };

  const MapClickHandler = () => {
    useMapEvent("click", () => {
      setActiveResource(null);
    });
    return null;
  };

  return (
    <div className="map-container">
      <MapContainer
        center={[40.4637, -3.7492]}
        zoom={6}
        style={{ height: "100%", width: "100%" }}
      >
        <MapClickHandler />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {resources.map((resource) => (
          <Marker
            key={resource.resourceId}
            position={[resource.latitude, resource.longitude]}
            icon={customIcon}
            eventHandlers={{
              click: () => {
                handleMarkerClick(resource);
              },
            }}
          />
        ))}

        {activeResource && (
          <Popup
            position={[activeResource.latitude, activeResource.longitude]}
            onClose={() => {
              setActiveResource(null);
            }}
          >
            <div className="marker-resource-popup">
              <img
                src={activeResource.resourceImage}
                alt={activeResource.title}
              />
              <div className="marker-resource-popup-info">
                <span>
                  {activeResource.title}
                  <img
                    className="license-icon"
                    src={getLicenseIcon(activeResource.rights)}
                    title={getLicenseType(activeResource.rights)}
                  ></img>
                </span>
                <span style={{ fontWeight: 600 }}>
                  {activeResource.referredAuthor}
                </span>
                <span>{activeResource.username}</span>
              </div>
            </div>
            <Link
              className="button-open-resource button-open-resource-extra marker-button-small"
              to={"resource?resourceId=" + activeResource.resourceId}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <div className="button-small-text">ACCESS</div>
            </Link>
          </Popup>
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
