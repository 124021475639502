import React, { useState, useEffect } from "react";
import "../css/SelectMultiple.css";

export default function SelectMultiple(props) {
  const [selectedOptionList, setSelectedOptionList] = useState([]);

  useEffect(() => {
    setSelectedOptionList(props.selectedOps);
  }, []);

  const options = props.optionList.map((item, i) => {
    return (
      <div
        key={i}
        className="op-container"
        onClick={() => {
          selectOption(item);
        }}
      >
        <div className="option-checked">
          {selectedOptionList.some((e) => e.id === item.id) && (
            <img src="images/check.svg"></img>
          )}
        </div>
        <span>{item.name}</span>
      </div>
    );
  });

  const optionsSelected = selectedOptionList.map((item, i) => {
    return (
      <div className="op-selected-container" key={i}>
        <span>{item.name}</span>
        <img
          src="images/delete-element.svg"
          onClick={(ev) => {
            ev.stopPropagation();
            const isOptionSelected = selectedOptionList.some(
              (e) => e.id === item.id
            );
            if (isOptionSelected) {
              setSelectedOptionList(
                selectedOptionList.filter(
                  (selectedId) => selectedId.id !== item.id
                )
              );
              props.updateResource(item);
            }
          }}
        ></img>
      </div>
    );
  });

  const selectOption = (option) => {
    const isOptionSelected = selectedOptionList.some((e) => e.id === option.id);
    if (isOptionSelected) {
      setSelectedOptionList(
        selectedOptionList.filter((selectedId) => selectedId.id !== option.id)
      );
    } else {
      setSelectedOptionList([...selectedOptionList, option]);
    }
    props.updateResource(option);
  };

  return (
    <div>
      <div
        className="select-multiple-container"
        onClick={() => {
          props.handleToggle();
        }}
      >
        {optionsSelected}
        <img
          className="toggle-select"
          src="images/select-multiple-arrow.png"
        ></img>
      </div>
      {props.toggle && <div className="select-multiple-options">{options}</div>}
    </div>
  );
}
